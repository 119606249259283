import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as ManpowerReportStore from '../store/ManpowerReport';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Select from 'react-select';
import { RouteComponentProps } from 'react-router';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
//@ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
//@ts-ignore
import JSZip from 'jszip';
//@ts-ignore
import JSZipUtils from 'jszip-utils';
//@ts-ignore
import saveAs from 'save-as';
import { FaChevronLeft, FaSadCry, FaEllipsisH } from 'react-icons/fa';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IoCloseOutline } from 'react-icons/io5';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


const customStyles = {
    control: (base: any, state: { isFocused: any; }) => ({
        ...base,
        background: "#fff",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#f47d30" : "gray",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#f47d30" : "gray"
        }
    }),
    option: (base: any, state: { isSelected: any; }) => ({
        ...base,
        backgroundColor: state.isSelected ? "#f47d30" : "",
        color: state.isSelected ? "#fff" : "",
        fontWeight: "bold",
        "&:hover": {
            // Overwrittes the different states of border
            backgroundColor: "#f47d30",
            color: "#fff"
        }
    }),
    menu: (base: any) => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0
    }),
    menuList: (base: any) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    })
};

type ManpowerReportProps =
    ManpowerReportStore.ManpowerReportState &
    typeof ManpowerReportStore.actionCreators &
    RouteComponentProps<{}>;


class ManpowerReports extends React.PureComponent<ManpowerReportProps>
{
    public componentDidMount() {
        this.addAGGridHeaderStyle();
        this.ensureDataFetched();

    }

    addAGGridHeaderStyle = () => {
        let item = document.querySelectorAll('[class="ag-header-cell-text"][role="columnheader"]');
        for (let i = 0; i < item.length; i++) {
            if (item[i].textContent == 'Name') {
                item[i].classList.add("approval-ag-grid-header-margin");
                break;
            }
        }
    }

    private ensureDataFetched() {
        this.props.fetchReports();
    }

    onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();
    }

    onGridSizeChanged = (params: any) => {
        params.api.sizeColumnsToFit();
    }

    state = {
        dates: ""
    }
    addMonths = (date: any, months: any) => {
        date.setMonth(date.getMonth() + months);
        return date;
    }


    onDateRangeChange = (dates: any) => {
        this.setState({ dates })
        if (dates != null) {
            this.props.SetFilters(dates[0].toDateString(), dates[1].toDateString(), this.props.selectedStationId);
        }
        else {
            this.props.SetFilters(null, null, this.props.selectedStationId);
        }
    }

    onStationChange(event: any) {
        this.props.SetFilters(this.props.startDate, this.props.endDate, parseInt(event.id));
    }


    downloadAll = (files: any) => {
        if (files.length == 0) return;
        if (files.length == 1) {
            //var file = files.pop();
            const element = document.createElement("a");
            element.href = files;
            element.name = "myFile.xlsx";
            element.download = "myFile.xlsx";
            document.body.appendChild(element);

            element.click();
            element.remove();
        }
        else {
            const zip = new JSZip();
            let count = 0;
            const zipFilename = "ManpowerReports.zip";
            files.forEach(async function (url: any) {
                const urlArr = url.split('/');
                var filename = urlArr[urlArr.length - 1];
                filename = filename.split('?')[0];
                try {
                    const file = await JSZipUtils.getBinaryContent(url)
                    zip.file(filename, file, { binary: true });
                    count++;
                    if (count === files.length) {
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            saveAs(content, zipFilename);
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        }
        toast.dismiss();
        toast("Your Manpower Reports have been successfully exported.");
    }

    public backToReportList() {
        this.props.history.push("/Reporting");
    }

    onSelectionChanged = (params: any) => {
        var selectedRows = params.api.getSelectedRows();
        let reportIds: any = [];
        let reportUrls: any = [];
        selectedRows.forEach(function (selectedRow: any, index: any) {
            reportIds.push(selectedRow.id);
            reportUrls.push(selectedRow.docURL)
        });

        this.props.SetSelectedReportIds(reportIds,reportUrls);
    };

    public render() {

        return (
            <React.Fragment>
                {(this.props.isLoading) &&
                    <Loader fullPage={true} loading={this.props.isLoading} />
                }
                <div className="row">
                    <ToastContainer toastClassName="custom-info" hideProgressBar={true} position="bottom-center" />
                </div>
                <Modal centered={true} className="smallModal diff-hours-modal" isOpen={this.props.showDateRangeValidation}>
                    <ModalHeader className="modalHeader w-100">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.CloseDateRangeValidation() }}><IoCloseOutline /></div>

                            </div>
                        </div>
                        <div className="flex flex-center w-100">
                            <span>Date Range</span>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="flex flex-center w-100">
                                <div className="flex flex-center w-100 text-center"> Please select date range.</div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="flex flex-center w-100">
                            <div className="round-fill-button border-0 diff-hours-ok" onClick={() => { this.props.CloseDateRangeValidation() }}>OK</div>
                        </div>

                    </ModalFooter>
                </Modal>
                <div className="flex flex-space-between report-padding w-100">
                    <div>
                        <FaChevronLeft className="width-Fa-Left" onClick={() => { this.backToReportList(); }} /> <label className="planTitle userTitle"> Manpower Report</label>
                    </div>
                    <div className={"text-align-center round-fill-button  manpower-export-button " + (this.props.selectedReportIds.length == 0 ? "manpower-export-button-disable" : "")} onClick={() => { this.downloadAll(this.props.selectedReportUrls); }}>
                        EXPORT
                    </div>
                </div>
                <br />
                <div className="flex flex-space-between report-padding ">
                    <div className="col-5 p-0">
                        <p className="middleHeaderInstance">Date</p>
                        <DateRangePicker
                            className="w-100 text-align-center manpowerDate"
                            onChange={(range: any) => this.onDateRangeChange(range)}
                            value={this.state.dates}
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            maxDate={new Date()}
                            //minDate={this.addMonths(new Date(), -6)}

                        />
                    </div>
                    <div>
                        &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                    </div>
                    <div className="col-5 p-0">
                        <p className="middleHeaderInstance">Station</p>
                        <Select
                            styles={customStyles}
                            className="zIndex3 w-100"
                            options={this.props.stations}
                            placeholder="Select your station"
                            onChange={(event) => { this.onStationChange(event); }}
                            value={this.props.stations && this.props.stations.filter(stn => stn.id == this.props.selectedStationId)[0]}
                            isDisabled

                        />
                    </div>
                    <div>
                        &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                    </div>
                    <div className="">
                        <br />
                        <div className="mt-3 text-align-center round-empty-button print-schedule-style"
                           onClick={() => this.props.FetchFilteredReports(this.props.selectedStationId, this.props.startDate, this.props.endDate)}
                      >
                            FIND REPORT
                        </div>
                    </div>
                </div>
                <br />
                <div id="manpowerGrid" className="row report-padding ">
                    <div
                        className="col-12 ag-theme-balham manpowerGrid"
                    >
                        <AgGridReact
                            
                            suppressRowClickSelection={true}
                            onGridSizeChanged={this.onGridSizeChanged}
                            onGridReady={this.onGridReady}
                            enableSorting={true}
                            gridAutoHeight={true}
                            rowSelection={'multiple'}
                            rowHeight={45}
                            detailRowHeight={45}
                            enableFilter={true}
                            pagination={true}
                            columnDefs={this.props.columnDefs}
                            rowData={this.props.manpowerReports}
                            accentedSort={true}
                            paginationPageSize={10}
                            onSelectionChanged={this.onSelectionChanged}
                            onCellClicked={(events) => {
                               
                            }
                            }

                        >
                        </AgGridReact>
                    </div>
                </div>

            </React.Fragment>
        );
    }

};


export default connect((state: ApplicationState) => state.manpowerReport,
    ManpowerReportStore.actionCreators)(ManpowerReports as any);