import * as React from 'react';
import { connect } from 'react-redux';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar, DateRangePicker as DateRangePicker1, DateRange } from 'react-date-range';
import { ApplicationState } from '../store';
import * as DeferralReportStore from '../store/DeferralReport';
import { RouteComponentProps } from 'react-router';
//@ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker'

import Select from 'react-select';
//@ts-ignore
import JSZip from 'jszip';
//@ts-ignore
import JSZipUtils from 'jszip-utils';
//@ts-ignore
import saveAs from 'save-as';
import { FaChevronLeft, FaSadCry, FaEllipsisH } from 'react-icons/fa';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';



const customStyles = {
    control: (base: any, state: { isFocused: any; }) => ({
        ...base,
        background: "#fff",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#f47d30" : "gray",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#f47d30" : "gray"
        }
    }),
    option: (base: any, state: { isSelected: any; }) => ({
        ...base,
        backgroundColor: state.isSelected ? "#f47d30" : "",
        color: state.isSelected ? "#fff" : "",
        fontWeight: "bold",
        "&:hover": {
            // Overwrittes the different states of border
            backgroundColor: "#f47d30",
            color: "#fff"
        }
    }),
    menu: (base: any) => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0
    }),
    menuList: (base: any) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    })
};
type DeferralReportProps =
    DeferralReportStore.DeferralReportState &
    typeof DeferralReportStore.actionCreators &
    RouteComponentProps<{}>;

class DeferralReports extends React.PureComponent<DeferralReportProps> {
    public reportIds: number[] = [];
    public isAllChecked: boolean = false;
    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        console.log(new Date().toUTCString());
        this.props.SetDate([], new Date().toUTCString(), new Date().toUTCString(), 0, "", this.props.selectedReportIds, this.props.selectedDocs);
    }

    state = {
        dates: [new Date().toUTCString(), new Date().toUTCString()]
    }
    addMonths = (date:any, months:any) => {
        date.setMonth(date.getMonth() + months);
        return date;
    }


    onSelect = (dates: any) => {
        this.setState({ dates })
        if (dates != null) {
            this.props.SetDate(dates, dates[0].toDateString(), dates[1].toDateString(), this.props.shiftId, this.props.fleetId, this.props.selectedReportIds, this.props.selectedDocs)
        }
        else
            this.props.SetDate(dates, null, null, this.props.shiftId, this.props.fleetId, this.props.selectedReportIds, this.props.selectedDocs)
    }
    private handleReports(event: any, id: number, docURL: string) {
        this.props.UpdateReportIdsFilter(id, docURL, event.currentTarget.checked);
        this.reportIds = this.props.selectedReportIds;
        this.forceUpdate();
        console.log(this.reportIds);
        if (event.currentTarget.checked == false) {
            this.isAllChecked = false;
        }
    }
    public onSelectShift(event: any) {
        console.log(event)
        console.log(parseInt(event.id));
        this.props.SetDate([], this.props.startDate, this.props.endDate, parseInt(event.id), this.props.fleetId, this.props.selectedReportIds, this.props.selectedDocs);
    }

    public onSelectFleet(event: any) {
        console.log(event)
        console.log(event.value);
        this.props.SetDate([], this.props.startDate, this.props.endDate, this.props.shiftId, event.value, this.props.selectedReportIds, this.props.selectedDocs);

    }
    public ExportReport() {
        this.isAllChecked = false;
        console.log(this.props.selectedDocs);
        this.downloadAll(this.props.selectedDocs);
    }

    public ExportIndReport(reportid: any, docUrl: string) {
        this.isAllChecked = false;
        var selectedDocs = []
        selectedDocs.push(docUrl);
        console.log(selectedDocs);
        this.downloadAll(selectedDocs);

        //.push(docUrl)
    }
    public exportAllReports(event: any) {
        console.log(event)
        if (event.currentTarget.checked == true) {
            for (let i = 0; i < this.props.selectedDateRangeReports.length; i++) {
                this.props.UpdateReportIdsFilter(this.props.selectedDateRangeReports[i].id, this.props.selectedDateRangeReports[i].docURL, true
                );
            }
        }
        else {
            this.props.ClearReportIdsFilter();
        }
        this.reportIds = this.props.selectedReportIds;
        this.isAllChecked = event.currentTarget.checked;
        this.forceUpdate();
        //this.downloadAll(this.props.selectedDocs);
    }
    downloadFile = (data: any) => {
        if (data != "") {
            const element = document.createElement("a");
            element.href = data;
            element.name = "myFile.xlsx";
            element.download = "myFile.xlsx";
            document.body.appendChild(element);
            element.click();
            element.remove();
        }
    }
    downloadAll = (files: any) => {
        console.log(files);

        if (files.length == 0) return;
        if (files.length == 1) {
            var file = files.pop();
            const element = document.createElement("a");
            element.href = file;
            element.name = "myFile.xlsx";
            element.download = "myFile.xlsx";
            document.body.appendChild(element);

            element.click();
            element.remove();
        }
        else {
            const zip = new JSZip();
            let count = 0;
            const zipFilename = "Reports.zip";
            files.forEach(async function (url: any) {
                const urlArr = url.split('/');
                var filename = urlArr[urlArr.length - 1];
                filename = filename.split('?')[0];
                try {
                    const file = await JSZipUtils.getBinaryContent(url)
                    zip.file(filename, file, { binary: true });
                    count++;
                    if (count === files.length) {
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            saveAs(content, zipFilename);
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        }
        this.props.SetDate([], this.props.startDate, this.props.endDate, this.props.shiftId, this.props.fleetId, [], []);

    }
    public backToReportList() {
        this.props.history.push("/Reporting");
    }
    render() {
        return (
            <>
                
                <div className="flex flex-space-between report-padding w-100">
                    <div>
                        <FaChevronLeft className="width-Fa-Left" onClick={() => { this.backToReportList(); }} /> <label className="planTitle userTitle"> Daily Deferral Reports</label>
                    </div>
                    <div className="text-align-center round-empty-button print-schedule-style" onClick={() => { this.ExportReport(); }}>
                        Export
                    </div>
                </div>
                <br />
                <div className="flex flex-end report-padding">
                    <div className="">
                        <p className="middleHeaderInstance">Date</p>
                        <DateRangePicker
                            onChange={(range: any) => this.onSelect(range)}
                            value={this.state.dates}
                            maxDate={new Date()}
                            minDate={this.addMonths(new Date(), -6)}
                           
                        />
                    </div>
                    <div>
                        &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                    </div>
                    <div className="">
                        <p className="middleHeaderInstance">Shift</p>
                        <Select
                            styles={customStyles}
                            className="zIndex3 select-custom-deferral"
                            options={this.props.shiftList}
                            placeholder="Select your shift"
                            onChange={(event) => { this.onSelectShift(event); }}

                        />
                    </div>
                    <div>
                        &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                    </div>
                    <div className="">
                        <p className="middleHeaderInstance">Fleet</p>
                        <Select
                            styles={customStyles}
                            className="zIndex3 select-custom-deferral"
                            options={this.props.fleetList}
                            placeholder="Select Fleet Type"
                            onChange={(event) => { this.onSelectFleet(event); }}

                        />
                    </div>
                </div>

                <div>
                    <div className="flex flex-space-between headerMxR">
                        <div className="planningSubTitle subHeading-wip-list w-5">
                            <div className="containerCheckBox">
                                <div className="round deferralReport">
                                    <input type="checkbox" id="rdAll" className="" onChange={event => { this.exportAllReports(event) }}
                                        checked={this.isAllChecked}
                                    />
                                    <label htmlFor="rdAll" className="filter-checkbox-label bold-text">&nbsp;</label>
                                </div>
                            </div>

                        </div>
                        <div className="planningSubTitle header-report w-50">Report Name</div>
                        <div className="planningSubTitle header-report w-30">Shift</div>
                        <div className="planningSubTitle header-report w-20"></div>

                    </div>
                </div>
                {
                    this.props.selectedDateRangeReports != null && this.props.selectedDateRangeReports.map((req: any) =>
                        <div key={"inbound_" + req.id}>
                            <div className="flex flex-space-between rowMxR rowMxR-reports">
                                <div className="w-5 subheading-wip-data">
                                    <div className="round deferralReport">
                                        <input type="checkbox" id={"check_" + req.id} className=""
                                            onChange={event => { this.handleReports(event, req.id, req.docURL) }}
                                            //onChange={event => { this.exportAllReports(event) }}
                                            checked={this.props.selectedReportIds.some(n => n === req.id)}
                                            value={req.id}
                                        />
                                        <label htmlFor={"check_" + req.id} className="filter-checkbox-label bold-text">&nbsp;</label>
                                    </div>
                                </div>


                                <div className="w-50 menuItemMx">{req.reportName}</div>
                                <div className="w-30 menuItemMx">{req.shiftName}</div>
                                <div className="w-20 flex flex-end report-padding-2"><div>
                                    <UncontrolledDropdown className="contextMenuHeader">
                                        <DropdownToggle nav>
                                            <FaEllipsisH />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem className="" onClick={event => { this.ExportIndReport(req.id, req.docURL) }} >download</DropdownItem>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    
                                    </div></div>
                            </div>
                        </div>
                    )
                }



            </>
        )
    }
}
//export default DeferralReports as any;
export default connect(
    (state: ApplicationState) => state.deferralReport,
    DeferralReportStore.actionCreators
)(DeferralReports as any);