import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as WorksheetStore from '../store/WorkSheet';
import { FaChevronLeft } from 'react-icons/fa';
import Select from 'react-select';
import "react-datetime/css/react-datetime.css";
//@ts-ignore
import Datetime from "react-datetime";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, NavLink } from 'reactstrap';
import CrewDeBrief from './CrewDebrief';
import WorksheetWIP from './WorksheetWIP';
import moment from 'moment';
import { IoCreateOutline, IoTrashOutline, IoCloseOutline } from 'react-icons/io5';
import DeleteModal from './DeleteModal';

const customStyles = {
    control: (base: any, state: { isFocused: any; }) => ({
        ...base,
        background: "#fff",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#fff" : "#fff",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#fff" : "#fff"
        }
    })
}


type WorksheetProps =
    WorksheetStore.WorksheetState &
    typeof WorksheetStore.actionCreators &
    RouteComponentProps<{ tailRecordId: string }>;

class WorkSheet extends React.PureComponent<WorksheetProps> {
    ataDateTimeRef: any = React.createRef();
    atdDateTimeRef: any = React.createRef();    
    state = {      
        showATAModel: false,
        showATDModel: false,       
    }    
    public intervalId: any = {};
    public componentDidMount() {
        this.intervalId = setInterval(this.ensureDataFetched.bind(this), 2 * 60 * 1000);
        this.ensureDataFetched();
    }
    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    componentDidUpdate() {
        //this.validateSTASTD();
    }

    private ensureDataFetched() {
        var notFromWorksheet = false;
        if (this.props.history.location.state && this.props.history.location.state.notFromWorksheet) {
            notFromWorksheet = this.props.history.location.state.notFromWorksheet;
            delete this.props.history.location.state.notFromWorksheet;
        }
        this.props.getTailRecord(parseInt(this.props.history.location.state.tailRecordId, 0), notFromWorksheet ? 1 : this.props.currentStep);
    }

    public RedirectToWorkLoad() {
        this.props.history.push("/workload");
    }

    public onChangeAta(value: any) {               
            if (this.ValidateInput(value,"ATA")) {
                //this.setState({ lastValidATA: value });               
                this.props.updateATA(value);
                this.forceUpdate();                 
            }
            else {
                this.ataDateTimeRef.current._closeCalendar();
                this.setState({ showATAModel: true });
            }        
    }

    public onChangeAda(value: any) {
        if (this.ValidateInput(value, "ATD")) {
            //this.setState({ lastValidATD: value });
            this.props.updateADA(value);
            this.forceUpdate();
        }
        else {
            this.atdDateTimeRef.current._closeCalendar();
            this.setState({ showATDModel: true });                        
        }       
    }
     
    public renderView(mode:any, renderDefault:any) {
        return (
            <div className="wrapper">
                {renderDefault()}
                <div className="rdt-UTC">
                    Zulu Time
                </div>
            </div>
        );
    }
    
    public onOpenAta() {
       /* console.log("ata opening");*/
        if (this.props.ata == null)
        {
                const currentZulu = moment(new Date()).utc();
                this.ataDateTimeRef.current.state.selectedDate = currentZulu;                                   
                //this.onChangeAta(currentZulu);
                this.props.updateATA(currentZulu);
                this.forceUpdate();
        }        
    }
    public onOpenAtd() {
      /*  console.log("atd opening");*/
        if (this.props.atd == null) {
            const currentZulu = moment(new Date()).utc();
            this.atdDateTimeRef.current.state.selectedDate = currentZulu;
            //this.onChangeAda(currentZulu);
            this.props.updateADA(currentZulu);
            this.forceUpdate();
        }        
    }

    public updateStart() {
        this.onChangeStatus(3);
    }

    public onSelectStatus(event: any) {
        console.log(parseInt(event.value));
        this.onChangeStatus(parseInt(event.value));
    }

    public HandleGateUpdate(e: any) {
        if (e.key === 'Enter') {
            this.IsStateChanged(e,'GATE');
        }
    }

    public onChangeStatus(value: any) {
        console.log(value);
        this.props.updateStatus(value);
        this.props.updateState();
    }


    public onChangeGate(event: any) {
        console.log(event.target.value);
        this.props.updateGate(event.target.value);
        this.forceUpdate();
    }

    private AddDepartureEvent() {
        this.props.ShowAddDepartureNotes(parseInt(this.props.history.location.state.tailRecordId, 0));
    }

    private moveToMxSendEmail(stepToGo: number, sendEmail: boolean) {
        this.onChangeStatus(5);
        if (sendEmail) {
            this.props.moveStep(4, true);
        }
    }

    private ValidateInput(value: any, name: any) {
        const currentZulu = moment(new Date()).utc().format('MM/DD/YYYY HH:mm');
        if (name == "ATA") {
            let input = moment(value).format('MM/DD/YYYY HH:mm');            
            let diff = moment(currentZulu).diff(input, 'minutes');
            if (diff < 0) {                                
                this.props.UpdateATAInputValidation(false);
                return false;
            }
            else {                
                this.props.UpdateATAInputValidation(true);
                return true;
            }
        }
        else {
            let atdInput = moment(value).format('MM/DD/YYYY HH:mm');            
            let atdDiff = moment(currentZulu).diff(atdInput, 'minutes');
            if (atdDiff < 0) {                
                this.props.UpdateATDInputValidation(false);
                return false;
            }
            else {                
                this.props.UpdateATDInputValidation(true);
                return true;
            }

        }

    }
     


    private IsStateChanged(value: any, name: string) {
        var previousValue;
        var updatedValue = null;
        if (name === 'GATE') {
            updatedValue = value.target.value ? value.target.value : null;
            previousValue = this.props.gate ? this.props.gate : null;
        }
        else {
            value = value ? value : null;
            if (value != null) {                
                var receivedDate = new Date(value);
                updatedValue = receivedDate.getDate() + receivedDate.getMonth() + receivedDate.getFullYear() + receivedDate.getTime();                
            }
            if (name === 'ATA') {
                if (this.props.isATAValidInput)
                    previousValue = this.props.ata ? (this.props.ata.getDate() + this.props.ata.getMonth() + this.props.ata.getFullYear() + this.props.ata.getTime()) : null;                
            }
            else {                
                if (this.props.isATDValidInput)
                    previousValue = this.props.atd ? (this.props.atd.getDate() + this.props.atd.getMonth() + this.props.atd.getFullYear() + this.props.atd.getTime()) : null;
            }
        }
        if (name == 'ATA') {
            if (this.props.isATAValidInput && updatedValue !== previousValue) {
                this.props.updateState();
            }            
        }
        else if (name == 'ATD') {
            if (this.props.isATDValidInput && updatedValue !== previousValue) {
                this.props.updateState();
            }
        }
        else {
            if (updatedValue !== previousValue) {
                this.props.updateState();
            }
        }       
   }

    public render() {
        return (
            <React.Fragment>

                <DeleteModal
                    message={'This record will be deleted'}
                    isOpen={this.props.showDeleteDepartureEvent}
                    onConfirm={this.props.DeleteDepartureEvent}
                    onClose={this.props.CloseDeleteDepartureEvent}
                />
                {this.props.tailrecord &&
                    <Modal className="smallModal" centered={true} isOpen={this.props.confirmStart}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.toggleConfirmStart(false); }}><IoCloseOutline /></div>

                                </div>
                            </div>

                            <div className="flex flex-space-between w-100">
                                Confirmation
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="flex flex-space-between w-100">
                                <div>
                                    <div className="lh3">Status:</div>
                                    <div className="lh3">Customer:</div>
                                    <div className="lh3">Tail:</div>
                                    <div className="lh3">ETA:</div>

                                </div>
                                <div className="">
                                    <div className="lh3 float-right">{this.props.selectedStatus.label}</div>
                                    <div className="spacer2"></div>
                                    <div className="lh3 float-right">{this.props.tailrecord.airCrafts.customer.companyName} {this.props.tailrecord.airCrafts.acType}</div>
                                    <div className="spacer2"></div>
                                    <div className="lh3 float-right"> {this.props.tailrecord.airCrafts.tail} </div>
                                    <div className="spacer2"></div>
                                    <div className="lh3 float-right">{this.props.tailrecord.sta}</div>
                                </div>

                            </div>
                        </ModalBody>
                        <ModalFooter >
                            <div className="flex flex-button w-100">
                                <div className="round-empty-button-popup" onClick={() => { this.props.toggleConfirmStart(false); }} >
                                    CANCEL
                                </div>
                                
                            <div className="round-fill-button-popup-large margin-left-24" onClick={() => { this.updateStart(); }}>
                                    GET STARTED
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal >
                }
                {
                    <Modal className="smallModal" centered={true} isOpen={this.state.showATAModel}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.setState({ showATAModel: false }); this.ensureDataFetched(); }}><IoCloseOutline /></div>

                                </div>
                            </div>

                            <div className="flex w-100 centerContent">
                               Invalid ATA
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="flex w-100 centerContent">
                                <div>
                                    ATA time can not be greater than now (UTC).
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter >
                            <div className="flex flex-button w-100">
                                <div className="round-fill-button-popup model-btn-margin" onClick={() => { this.setState({ showATAModel: false }); this.ensureDataFetched();}}>
                                      Ok
                                    </div>                             
                            </div>
                        </ModalFooter>
                    </Modal >

                }
                {
                    <Modal className="smallModal" centered={true} isOpen={this.state.showATDModel}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.setState({ showATDModel: false }); this.ensureDataFetched(); }}><IoCloseOutline /></div>

                                </div>
                            </div>

                            <div className="flex w-100 centerContent">
                               Invalid ATD
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="flex w-100 centerContent">
                                <div>
                                    ATD time can not be greater than now (UTC).
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter >
                            <div className="flex flex-button w-100">
                                <div className="round-fill-button-popup model-btn-margin" onClick={() => { this.setState({ showATDModel: false }); this.ensureDataFetched();}}>
                                    Ok
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal >

                }

                {this.props.tailrecord &&

                    <Modal className="smallModal" centered={true} isOpen={this.props.showConfirmBox}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.moveStep(this.props.stepToGo, false); }}><IoCloseOutline /></div>

                                </div>
                            </div>

                        <div className="flex w-100 centerContent">
                                Confirmation to send
                            </div>
                        </ModalHeader>
                        <ModalBody>
                        <div className="flex w-100 centerContent">
                                <div>
                                    Once confirmed your worksheet will be sent.
                                </div>

                            </div>
                        </ModalBody>
                        <ModalFooter >
                            <div className="flex flex-button w-100">

                                {this.props.currentStep === 1 &&
                                    <div className="round-empty-button-popup" onClick={() => { this.props.moveStep(this.props.stepToGo, false); }} >
                                        NO, CANCEL
                                </div>}

                                {this.props.currentStep === 3 &&
                                <div className="round-empty-button-popup " onClick={() => { this.moveToMxSendEmail(this.props.stepToGo, false); }} >
                                        NO, CANCEL
                                </div>}

                                &nbsp;
                                {this.props.currentStep === 1 &&
                                <div className="round-fill-button-popup model-btn-margin model-btn-margin" onClick={() => { this.props.moveStep(this.props.stepToGo, true); }}>
                                        YES, SEND
                                </div>}

                                {this.props.currentStep === 3 &&
                                <div className="round-fill-button-popup model-btn-margin" onClick={() => { this.moveToMxSendEmail(this.props.stepToGo, true); }}>
                                        YES, SEND
                                </div>}
                            </div>
                        </ModalFooter>
                    </Modal >
                }
               {(this.props.isLoading) &&
                    <Loader fullPage={true} loading={this.props.isLoading} />
                }
                <div className="row">
                    <ToastContainer toastClassName="custom-info" hideProgressBar={true} position="bottom-center" />
                </div>
                {!this.props.isLoading && this.props.tailrecord !== null &&
                    <fieldset disabled={!this.props.isEditable}>
                        <div className="col-12 mobile0">
                        <span className="underConstruction" >Under Construction</span><br />
                        <div className="worksheetTitle" >
                            <FaChevronLeft style={{ cursor: "pointer" }} onClick={() => { this.RedirectToWorkLoad(); }} />  <span className="dimTitle">&nbsp; &nbsp;Workload / </span> Work Sheet {this.props.tailrecord.airCrafts.tail}
                            </div>
                            <div className="workSheetHeader worksheetOuter">
                                <div className='workSheetRow'>
                                    <div className="workSheetColumn">
                                        <div className="flex flex-align-center" style={!this.props.isEditable ? { pointerEvents: "none" } : {}}>
                                            <span>Status:</span>
                                            <Select
                                                styles={customStyles}
                                                className="zIndex3 select-custom-border-0"
                                                options={this.props.statuses}
                                                defaultValue={this.props.selectedStatus}
                                                onChange={(event) => { this.onSelectStatus(event); }}

                                            />
                                        </div>

                                    </div>
                                    <div className="workSheetColumn">
                                        <div>
                                            <span>Gate:</span>  <input type="text"
                                                placeholder="Insert Gate"
                                                defaultValue={this.props.gate}
                                            className="form-border margin-left-minus-3"
                                                onBlur={(event: any) => { this.IsStateChanged(event, 'GATE'); }}
                                                onKeyDown={(event) => { this.HandleGateUpdate(event) }}
                                                onChange={(event: any) => { this.onChangeGate(event); }} />
                                        </div>

                                    </div>
                                </div>
                                <div className='workSheetRow'>
                                    <div className="workSheetColumn">
                                        <div>
                                            <span> Customer:</span> {this.props.tailrecord.airCrafts.customer.companyName} {this.props.tailrecord.airCrafts.acType}
                                        </div>

                                    </div>
                                    <div className="workSheetColumn">
                                        <div>
                                        <span> Tail:</span> <span className="margin-left-minus-3">{this.props.tailrecord.airCrafts.tail}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='workSheetRow'>

                                    <div className="workSheetColumn">
                                        <div>
                                        <span> ETA:</span> {this.props.tailrecord.worksheetSTA} <span className="flightPad">{this.props.tailrecord.arrivingStationName} #{this.props.tailrecord.flightNo}</span>
                                        </div>

                                    </div>

                                    <div className="workSheetColumn">
                                        <div className="flex flex-align-center" style={!this.props.isEditable ? { pointerEvents: "none" } : {}}>
                                        <span> ATA:</span>                                                                                
                                        <Datetime timeFormat="HH:mm" utc={true} key="ATACalendar"
                                            initialValue={this.props.ata ? this.props.ata:''}
                                            onChange={(date: any) => { this.onChangeAta(date); }}
                                            onClose={(date: any) => { this.IsStateChanged(date, 'ATA'); }}
                                            onOpen={() => { this.onOpenAta(); }}
                                            ref={this.ataDateTimeRef}
                                            renderView={(mode, renderDefault) =>
                                                this.renderView(mode, renderDefault)
                                            }
                                            inputProps={{ disabled: this.props.isDisableSTA }}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className='workSheetRow'>
                                    <div className="workSheetColumn">
                                        <div>
                                            <span> ETD:</span> {this.props.tailrecord.worksheetSTD} <span className="flightPad">{this.props.tailrecord.departingStationName} #{this.props.tailrecord.departingFlightNo}</span>
                                        </div>

                                    </div>
                                    <div className="workSheetColumn">
                                        <div className="flex flex-align-center" style={!this.props.isEditable ? { pointerEvents: "none" } : {}}>
                                            <span> ATD:</span>
                                        <Datetime timeFormat="HH:mm" utc={true} key="ATDCalendar"
                                            initialValue={this.props.atd ? this.props.atd:''}
                                            onChange={(date: any) => { this.onChangeAda(date); }}
                                            onClose={(date: any) => { this.IsStateChanged(date, 'ATD'); }}
                                            onOpen={() => { this.onOpenAtd(); }}                                            
                                            ref={this.atdDateTimeRef}
                                            renderView={(mode, renderDefault) =>
                                                this.renderView(mode, renderDefault)
                                            }
                                            inputProps={{ disabled: this.props.isDisableSTD }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <hr className="line2" />
                                <div className="flex flex-space-between" style={!this.props.isEditable ? { pointerEvents: "none" } : {}}>
                                    {(this.props.tailrecord.flightStatusId !== 6) &&
                                        <div className="markAog cursor-pointer" onClick={() => { this.onChangeStatus(6); }} >MARK AS AOG</div>
                                    }

                                    {(this.props.tailrecord.flightStatusId === 6) &&
                                        <div className="markAog" >MARKED AS AOG</div>
                                    }

                                    {(this.props.tailrecord.flightStatusId < 3) &&
                                        <div className="round-fill-button-2" onClick={() => { this.props.toggleConfirmStart(true); }} >GET STARTED</div>
                                    }
                                    {(this.props.tailrecord.flightStatusId >= 3) &&
                                        <div className="statusPad1"
                                        >
                                            {this.props.tailrecord.flightStatusId == 5 ? "MX Ready" : "In Work"}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </fieldset>
                }
                {console.log("checking props")}
                {console.log(this.props.currentStep)}
                {this.props.tailrecord && this.props.tailrecord.flightStatusId >= 3 &&
                    <div>
                        <div className="buttonBar">
                        <nav className="nav nav-pills nav-fill">
                            <a className={this.props.currentStep === 1 ? "nav-link active" : "nav-link custom"} onClick={() => { this.props.stepCalled(1, false); }} >Step 1 <span className="worksheet-step">Crew Debrief</span></a>
                            <a className={this.props.currentStep === 2 ? "nav-link active" : "nav-link custom"} onClick={() => { this.props.stepCalled(2, false); }}>Step 2 <span className="worksheet-step">WIP</span></a>
                            <a className={this.props.currentStep === 3 ? "nav-link active" : "nav-link custom"} onClick={() => { this.props.stepCalled(3, false); }}>Step 3 <span className="worksheet-step">Finalize</span></a>
                            </nav>
                        </div>
                        <div>
                            {this.props.currentStep === 1 && this.props.tailrecord.flightStatusId >= 3 &&
                                //@ts-ignore
                                <CrewDeBrief
                                    key="step1CD"
                                    tailRecordId={this.props.tailrecord.id}
                                    stepNumber={1} isEditable={this.props.isEditable}
                                    callStep={this.props.stepCalled}                            
                                />
                            }
                            {this.props.currentStep === 2 && this.props.tailrecord.flightStatusId >= 3 &&
                                //@ts-ignore
                                <WorksheetWIP key="step2CD"
                                    recordId={this.props.tailrecord.id}
                                    isEditable={this.props.isEditable}
                                    isGetStarted={true}
                                />
                            }
                            {this.props.currentStep === 3 && this.props.tailrecord.flightStatusId !== 5 && this.props.isEditable
                                &&
                            <div className="mxButton">
                                <div className="round-fill-button-2" onClick={() => { this.props.stepCalled(3, true) }} >MX Ready</div>
                                </div>
                            }

                            {this.props.currentStep === 3 && this.props.tailrecord.flightStatusId >= 3 &&
                                //@ts-ignore
                                <CrewDeBrief
                                    key="step3CD"
                                    tailRecordId={this.props.tailrecord.id}
                                    stepNumber={3}
                                    isEditable={this.props.isEditable}
                                    callStep={this.props.stepCalled}
                                />

                            }
                        </div>
                    </div>
                }
                {
                    this.props.tailrecord && this.props.tailrecord.flightStatusId < 3 &&
                    //@ts-ignore
                    <WorksheetWIP key="step4CD"
                        recordId={this.props.tailrecord.id}
                        isEditable={false}
                        isGetStarted={false}
                    />
                    
                }

                <br />
                <div className="hide-from-mobile">
                    {this.renderDepartureEvents()}
                </div>
                <div className="hide-in-big-screen show-in-mobile">
                    {this.renderDepartureEventInMobile()}
                </div>

                <Modal centered={true} isOpen={this.props.showDepartureEvent} >
                    <ModalHeader className="modalHeader">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.CloseDepartureEvent(); }}><IoCloseOutline /></div>

                            </div>
                        </div>
                        <div className="text-align-center">
                            {this.props.selectedEvent.id === 0 ? "Add New Departure Event" : "Edit Departure Event"}:
                        </div>

                    </ModalHeader>
                    <ModalBody>
                        <div className="modalLabel">Departure Event</div><br />
                        <textarea rows={4}
                            defaultValue={this.props.selectedEvent.eventText}
                            onChange={this.props.UpdateNotes}
                            placeholder="Enter your Departure Events here..."
                            className="form-control w-100" />
                        <div>&nbsp;
                            </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="round-empty-button cancel-popover-button" onClick={() => { this.props.CloseDepartureEvent(); }}>
                            CANCEL
                        </div>
                        <div className={this.props.selectedEvent.id === 0 ? "save-button-width-add round-fill-button border-0 save-popover-button" : "round-fill-button border-0 save-popover-button"} onClick={() => { this.props.SaveNote(); }}>
                            {this.props.selectedEvent.id === 0 ? "SAVE" : "SAVE CHANGES"}
                        </div>
                    </ModalFooter>
                </Modal>                
            </React.Fragment>
        );
    }

    private renderDepartureEvents() {
        return (
            <>
                <div className="outerDivMxR" >
                    <div>
                        <div className="flex flex-space-between headerMxR">
                            <div className="planningSubTitle subHeading-wip-list w-40">Departure Event</div>
                            <div className="planningSubTitle subHeading-wip-list w-25">Time</div>
                            <div className="planningSubTitle subHeading-wip-list w-25">Author</div>
                            {this.props.isEditable &&
                                <div className="planningSubTitle subHeading-wip-list text-center w-10">Edit</div>
                            }
                            {this.props.isEditable &&
                                <div className="planningSubTitle subHeading-wip-list text-right-align w-10">Delete</div>
                            }

                        </div>
                    </div>
                    {this.props.tailrecord != null && this.props.tailrecord.departureEvents.length === 0 &&
                        <div className="worksheetSubTitle text-align-center padding-top-15">
                            No departure event is available.
                            </div>
                    }
                    {this.props.tailrecord != null && this.props.tailrecord.departureEvents.map((req: any) =>
                        <div key={req.id}>
                            <div className="flex flex-space-between rowMxR">
                                <div className="w-40 subheading-wip-data">{req.eventText}</div>
                                <div className="w-25 subheading-wip-data">{req.createdDate}</div>
                                <div className="w-25 subheading-wip-data">
                                    {req.userName}
                                </div>
                                {this.props.isEditable &&
                                    <div className="w-10 text-center subheading-wip-data">
                                    <IoCreateOutline className="cursor-pointer" onClick={event => { this.props.EditDepartureEvent(req); }} />
                                    </div>
                                }
                                {this.props.isEditable &&
                                    <div className="w-10 text-right-align subheading-wip-data">
                                    <IoTrashOutline className="cursor-pointer" onClick={event => { this.props.ShowDeleteDepartureEvent(req); }} />
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                    {this.props.isEditable && <div className="fullFooterButton flex flex-center" onClick={(event) => { this.AddDepartureEvent() }}>
                        + ADD DEPARTURE EVENTS</div>}
                </div>
            </>
        );
    }
    private renderDepartureEventInMobile() {
        return (
            <>
                <div className="outerDivMxR outerDivMxWip" >
                    <div className="flex flex-space-between headerMxR heading-wip ">
                        Departure Events:
                        </div>
                    {this.props.tailrecord != null && this.props.tailrecord.departureEvents.length === 0 &&
                        <div className="planningSubTitle text-align-center padding-top-15">
                            No departure event is available.
                            </div>
                    }
                    {this.props.tailrecord != null && this.props.tailrecord.departureEvents.map((req: any) =>
                        <div key={"mobile_departure_event_" + req.id}>
                            <div className="">
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Departure Event</label><label className="subheading-wip-data text-align-right text-right-align width-180-mx">{req.eventText}</label></div>
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Time</label> <label className="subheading-wip-data text-align-right text-right-align width-180-mx">{req.createdDate}</label></div>
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Author</label><label className="subheading-wip-data text-align-right text-right-align width-180-mx">
                                    {req.userName}</label>
                                </div>
                                {this.props.isEditable && <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Edit</label> <label className="subheading-wip-data text-align-right text-right-align  width-180-mx">
                                    <IoCreateOutline className="cursor-pointer" onClick={event => { this.props.EditDepartureEvent(req) }} />
                                </label></div>}
                                {this.props.isEditable && <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Delete
                                </label><label className="subheading-wip-data text-align-right text-right-align width-180-mx">
                                        <IoTrashOutline className="cursor-pointer" onClick={event => { this.props.ShowDeleteDepartureEvent(req) }} />
                                    </label>
                                </div>}<br />

                            </div>
                        </div>
                    )}
                    {this.props.isEditable && <div className="fullFooterButton flex flex-center" onClick={(event) => { this.AddDepartureEvent() }}><span className="addmxrequest-button">+ ADD DEPARTURE EVENTS</span></div>
                    } </div>
            </>
        );
    }

};

export default connect(
    (state: ApplicationState) => state.worksheet,
    WorksheetStore.actionCreators
)(WorkSheet as any);
