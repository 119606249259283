import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';

export interface SelectedReportsDateRange {
    id: number;
    shiftId: number;
    ShiftName: string;
    ReportName: string;
    ReportDate: Date;
    StationId: number;
    docURL: string;
}

export interface DeferralReportState {
    fleetList: DropDownTypes[];
    shiftList: DropDownTypes[];
    isLoading: boolean;
    startDate: any;
    endDate: any;
    fullDate: [];
    selectedDateRangeReports: SelectedReportsDateRange[];
    fleetId: any;
    shiftId: any;
    selectedReportIds: number[];
    selectedDocs: any[];
}
export interface DropDownTypes {
    id: number,
    requestText: string
}
export interface ReceiveReportsAction {
    type: 'RECEIVE_REPORTS'; selectedDateRangeReports: any, fleetList: DropDownTypes[], shiftList: DropDownTypes[], selectedReportIds: number[], selectedDoc: any[]
}
export interface SetDateAction { type: 'SET_DATE'; fullDate: [], startDate: Date, endDate: Date, shiftId: any, fleetId: any, selectedReportIds: number[], selectedDoc: any[] }
export interface UpdateReportIdsFilterAction {
    type: 'UPDATE_REPORT_IDS_FILTER', reportIds: number[], docURLs: any[]
}
export interface ClearReportIdsFilterAction {
    type: 'CLEAR_REPORT_IDS_FILTER', reportIds: number[], docURLs: any[]
}
export type KnownAction = SetDateAction | ReceiveReportsAction | UpdateReportIdsFilterAction | ClearReportIdsFilterAction;

export const actionCreators = {
    
    SetDate: (fullDate: [], startDate: any, endDate: any, shiftId: any, fleet: any, selectedReportIds: number[]
        , selectedDocs: any[]
    ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        var stationId = "";
        if (appState && appState.station && appState.station.selectedStation) {
            stationId = appState.station.selectedStation.value;
        }

        fetch(`api/reportheads/FetchReports?startDate=${startDate}&endDate=${endDate}
                                &stationId=${stationId}&shiftId=${shiftId}&fleetId=${fleet}`
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json() as Promise<any>)
            .then(data => {
                //console.log(data);
                dispatch({
                    type: 'RECEIVE_REPORTS',
                    fleetList: data.fleetList,
                    selectedDateRangeReports: data.selectedDateRangeReports,
                    shiftList: data.shiftList,
                    selectedReportIds: selectedReportIds == undefined ? [] : selectedReportIds,
                    selectedDoc: selectedDocs == undefined ? [] : selectedDocs,
                });
            });

        dispatch({ type: 'SET_DATE', fullDate: fullDate, startDate: startDate, endDate: endDate, fleetId: fleet, shiftId: shiftId, selectedReportIds: selectedReportIds, selectedDoc: selectedDocs });
    },
    UpdateReportIdsFilter: (reportId: number, docURL: string, isSelected: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();        
        if (appState && appState.deferralReport) {
            if (isSelected == true) {
                appState.deferralReport.selectedReportIds.push(reportId);
                appState.deferralReport.selectedDocs.push(docURL);
            }
            else {
                var reportIdIndex = appState.deferralReport.selectedReportIds.indexOf(reportId);
                appState.deferralReport.selectedReportIds.splice(reportIdIndex,1);
                var reportDocIndex = appState.deferralReport.selectedDocs.indexOf(docURL);
                appState.deferralReport.selectedDocs.splice(reportDocIndex,1);
            }
            dispatch({ type: 'UPDATE_REPORT_IDS_FILTER', reportIds: appState.deferralReport.selectedReportIds, docURLs: appState.deferralReport.selectedDocs })

        }

    },
    ClearReportIdsFilter: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        dispatch({ type: 'CLEAR_REPORT_IDS_FILTER', reportIds: [], docURLs: [] })


    },
    ExportReports: (reportIds: number[], selectedDocs: any[], downloadFile: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(selectedDocs);
        for (let i = 0; i < selectedDocs.length; i++) {
            downloadFile(selectedDocs[i].toString())
        }
    }
}

export const reducer: Reducer<DeferralReportState> = (state: DeferralReportState | undefined, incomingAction: Action): DeferralReportState => {
    if (state === undefined) {
        return {
            endDate: new Date(),
            fleetList: [],
            fullDate: [],
            isLoading: false,
            shiftList: [],
            startDate: new Date(),
            selectedDateRangeReports: [],
            fleetId: "",
            shiftId: 0,
            selectedReportIds: [],
            selectedDocs: []
        };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_REPORTS':
            return {
                startDate: state.startDate,
                endDate: state.endDate,
                fleetList: action.fleetList,
                fullDate: state.fullDate,
                isLoading: false,
                shiftList: action.shiftList,
                selectedDateRangeReports: action.selectedDateRangeReports,
                shiftId: state.shiftId,
                fleetId: state.fleetId,
                selectedReportIds: action.selectedReportIds,
                selectedDocs: action.selectedDoc
            };
        case 'SET_DATE':
            return {
                startDate: action.startDate,
                endDate: action.endDate,
                fleetList: [],
                fullDate: action.fullDate,
                isLoading: true,
                shiftList: [],
                selectedDateRangeReports: [],
                shiftId: action.shiftId,
                fleetId: action.fleetId,
                selectedReportIds: action.selectedReportIds,
                selectedDocs: action.selectedDoc
            };
        case 'CLEAR_REPORT_IDS_FILTER':
            return {
                startDate: state.startDate,
                endDate: state.endDate,
                fleetList: state.fleetList,
                fullDate: state.fullDate,
                isLoading: false,
                shiftList: state.shiftList,
                selectedDateRangeReports: state.selectedDateRangeReports,
                shiftId: state.shiftId,
                fleetId: state.fleetId,
                selectedReportIds: action.reportIds,
                selectedDocs: action.docURLs
            };
        case 'UPDATE_REPORT_IDS_FILTER':
            return {
                startDate: state.startDate,
                endDate: state.endDate,
                fleetList: state.fleetList,
                fullDate: state.fullDate,
                isLoading: false,
                shiftList: state.shiftList,
                selectedDateRangeReports: state.selectedDateRangeReports,
                shiftId: state.shiftId,
                fleetId: state.fleetId,
                selectedReportIds: action.reportIds,
                selectedDocs: action.docURLs
            };
        default:
            return state;
    }
}