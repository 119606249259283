import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as PlanningStore from '../store/Planning';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar, FaCaretDown, FaCalendarAlt, FaRegCalendar, FaChevronLeft, FaChevronDown, FaAngleDown, FaEdit, FaTrashAlt, FaEllipsisH } from 'react-icons/fa';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import Select from 'react-select';
import 'react-overlay-loader/styles.css';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { render } from 'react-dom';

import { IoCreateOutline, IoTrashOutline, IoFunnelOutline, IoArrowUpOutline, IoCloseOutline, IoChevronUp, IoChevronDown } from 'react-icons/io5'
import { FieldProps } from "react-final-form"
import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import dtcLogo from '../dtc.png';
import lineLogo from '../Line.png';
import { AiOutlineFile, AiOutlineCloseCircle } from 'react-icons/ai';
import ascending from '../icons/sort/up.png';
import descending from '../icons/sort/down.png';
import DeleteModal from './DeleteModal';
import { ErrorModal } from './Shared/Modal/ErrorModal';
import { ConfirmationModal } from './Shared/Modal/ConfirmationModal';

type PlanningProps =
    PlanningStore.PlanningState &
    typeof PlanningStore.actionCreators &
    RouteComponentProps<{}>;



// @ts-ignore
const FileField: React.FC<FieldProps<FileList, HTMLInputElement>> = ({ name, ...props }: any) => {

    const [fileCount, setCount] = useState({ fileCount: 0 });

    return (<Field name={name}>
        {({ input: { value, onChange, ...input } }) => {


            const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
                onChange(target.files) // instead of the default target.value
            }
            return (
                <>

                    <input accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.JPG,.JPEG,.PNG,.PDF,.DOC,.DOCX" {...input} type="file" multiple
                        onChange={(event: any) => { setCount({ fileCount: event.target.files.length }); handleChange(event); }} className="custom-file-input" {...props} id="files" aria-describedby="fileHelp" />
                    <label className="custom-file-label" htmlFor="files">
                        Files (optional)&nbsp;&nbsp;
                        {fileCount.fileCount === 0 ? "" : fileCount.fileCount.toString() + " files selected"}

                    </label>



                </>
            )
        }}
    </Field>)
}


const ReactSelectAdapter = ({ input, ...rest }: any) => (
    <Select
        defaultValue={{ value: "0", label: "Select type" }}
        styles={customStyles} {...input} {...rest} />
)
const ReactSelectAdapterFlight = ({ input, ...rest }: any) => (
    <Select className="zIndex3"
        defaultValue={{ value: "0", label: "Select Flight" }}
        styles={customStyles} {...input} {...rest} />
)
const ReactSelectAdapterWithoutZindex = ({ input, ...rest }: any) => (
    <Select className=""
        defaultValue={{ value: "0", label: "Select Fleet" }}
        styles={customStyles} {...input} {...rest} />
)

const customStyles = {
    control: (base: any, state: { isFocused: any; }) => ({
        ...base,
        background: "#fff",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#f47d30" : "#d6d8e7",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#f47d30" : "#d6d8e7"
        }
    }),
    option: (base: any, state: { isSelected: any; }) => ({
        ...base,
        backgroundColor: state.isSelected ? "#ffd0bc" : "#fff",
        color: state.isSelected ? "#f47d30" : "#636569",
        "&:hover": {
            // Overwrittes the different states of border
            backgroundColor: "#ffd0bc",
            color: "#f47d30"
        }
    }),
    menu: (base: any) => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0,
        paddingLeft: 10,
    })
}

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#121212',
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: 'Poppins',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
};

const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const sortByValues = [
    { value: 1, label: 'Arrival Time' },
    { value: 2, label: 'Ground Time' },
    { value: 3, label: 'Incomplete items' }
]

class Planning extends React.PureComponent<PlanningProps> {
    myRef: any;
    public filterParams: any = {
        sortValue: 0,
        sortType: "asc",
        showArchivedFlights: false
    };
    public filterPreviousParams: any = {
        sortValue: 0,
        sortType: "asc",
        showArchivedFlights: false
    };
    public showArchivedFlights: boolean = false;
    public showArchiveCondition: boolean = false;
    constructor(props: any) {
        super(props)
        this.myRef = React.createRef()
    }

    public navigateToWorksheet(tailRecordId: number) {
        var path = "/worksheet/";
        this.props.history.push({
            pathname: path,
            state: {
                tailRecordId: tailRecordId,
                notFromWorksheet: true
            }
        });
    }

    public componentDidMount() {
        this.props.UpdateFilterValues(this.filterParams);
        this.ensureDataFetched();
    }

    public componentDidUpdate() {                 
    }

    private ensureDataFetched() {
        this.props.SelectDat(new Date(), false);
    }

    public SubmitParts(values: any) {
        this.props.SavePart(values);

    }
    public printReport() {
        if (this.props.selectedPrintId == 1)
            this.props.SavePrintSchedule(this.props.selectedPrintId, 0, "", this.downloadFile);
        else if (this.props.selectedPrintId == 2)
            this.props.SavePrintSchedule(this.props.selectedPrintId, this.props.selectedFlightId, this.props.selectedFlightDetails, this.downloadFile);
        else
            this.props.SavePrintSchedule(this.props.selectedPrintId, this.props.selectedFleetId, this.props.selectedFleetDetails, this.downloadFile);



    }
    downloadFile = (data: any) => {
        if (data != "") {
            const element = document.createElement("a");
            element.href = data;
            element.name = "myFile.pdf";
            element.download = "myFile.pdf";
            document.body.appendChild(element);
            element.click();
        }
    }

    public SelectStatusOnPage(event: any, mddrId: any) {
        this.props.SelectStatus(event, mddrId);
    }

    public OnChangeFlight(e: any) {
        this.props.UpdateSelectedFlightDetailsSchedule(e.value, e.label);
    }
    public OnChangeFleet(e: any) {
        this.props.UpdateSelectedFleetDetailsSchedule(e.value, e.label);
    }
    public OnBackToTop() {
        if (this.props.selectedTail != null) {
            this.props.SelectTail(this.props.tailRecords[0])
        }
        this.myRef.current.scrollTop = 0;
        this.props.ToggleTop(false);
    }
    public handleScroll() {
        if (this.myRef.current.scrollTop > (window.innerHeight / 2)) {
            this.props.ToggleTop(true);
        }
        else {
            this.props.ToggleTop(false);
        }
    }

    onFileChange = (event: any) => {
        this.props.select(event.target.files);
    };

    onDelete(file: File, filename: string) {
        this.props.deleteFile(file, filename)
    }

    onInputClick = (event: any) => {
        event.target.value = ''
    }

    CustomInput = ({ value, onClick }: { value: any, onClick: any }) => (
        <div>

            <div className="calendar-custom-input filter-height-planning" onClick={onClick}>
                <FaRegCalendar /> &nbsp;{value}
                &nbsp;<IoChevronDown />
            </div>
        </div>
    );

    onClickOutsideListener = () => {
        this.CloseFilterPopup();
        document.removeEventListener("click", this.onClickOutsideListener)
    }

    FilterSortData() {
        this.filterPreviousParams.sortValue = this.props.filterValues.sortValue;
        this.filterPreviousParams.showArchivedFlights = this.props.filterValues.showArchivedFlights;
        this.filterPreviousParams.sortType = this.props.filterValues.sortType;
        this.showArchivedFlights = this.props.filterValues.showArchivedFlights;
        this.props.HandleFilterSort();
    }
    AssignFilterValues(event: any, name: string) {
        if (name === 'sort') {
            this.filterParams.sortValue = event.value;
            this.filterPreviousParams.sortValue = this.props.filterValues.sortValue;
        }
        else if (name === 'archive') {
            this.filterParams.showArchivedFlights = event.target.checked;
            this.filterPreviousParams.showArchivedFlights = this.props.filterValues.showArchivedFlights;
        }
        else {
            this.filterParams.sortType = name;
            this.filterPreviousParams.sortType = this.props.filterValues.sortType;
        }
        this.props.UpdateFilterValues(this.filterParams);
    }

    CloseFilterPopup() {
        this.filterParams.sortValue = this.filterPreviousParams.sortValue;
        this.filterParams.showArchivedFlights = this.filterPreviousParams.showArchivedFlights;
        this.filterParams.sortType = this.filterPreviousParams.sortType;
        this.props.UpdateFilterValues(this.filterPreviousParams);
        this.props.CloseFilterPopup();
    }

    openFile = (fileWrapper: any) => {
        if (fileWrapper.uri.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
            this.props.selectImage({ fileName: fileWrapper.uri, imageUri: fileWrapper.imageUri })
        }
        else if (fileWrapper.uri.match(/.(xls|XLS|xlsx|XLSX)$/i)) {
            this.downloadFile(fileWrapper.imageUri);
        }
    };

    handleArchive = () => {
        const inbound = this.props.selectedTail && this.props.selectedTail.tail.inboundItems.length > 0;
        const attachment = this.props.selectedTail && this.props.selectedTail.tail.tailRecordDocuments.length > 0;
        const mxRequest = this.props.selectedTail && this.props.selectedTail.tail.mxrequests.length > 0;
        const defect = this.props.selectedTail && this.props.selectedTail.defects.length > 0;

        if (inbound
            ||
            attachment
        ) {
            this.props.ShowArchiveWarning();
        }
        else if (defect || mxRequest) {
            this.props.ShowArchiveConfirmation();
        }
        else {
            this.props.ShowArchiveConfirmation();
        }
    }

    handleReplace = () => {
        const inbound = this.props.selectedTail && this.props.selectedTail.tail.inboundItems.length > 0;
        const attachment = this.props.selectedTail && this.props.selectedTail.tail.tailRecordDocuments.length > 0;
        const mxRequest = this.props.selectedTail && this.props.selectedTail.tail.mxrequests.length > 0;
        const defect = this.props.selectedTail && this.props.selectedTail.defects.length > 0;

        if (inbound
            ||
            attachment
        ) {
            this.props.ShowReplaceWarning();
        }
        else if (defect || mxRequest) {
            this.props.ShowReplaceConfirmation();
        }
        else {
            this.props.ShowReplaceConfirmation();
        }         
    }

    public render() {
        return (
            <React.Fragment>
                <div onMouseLeave={() => {
                    document.addEventListener("click", this.onClickOutsideListener);
                }}>
                    {(this.props.isLoading || this.props.isUpdatingMddr) &&
                        <Loader fullPage={true} loading={this.props.isLoading || this.props.isUpdatingMddr} />
                    }
                    <div className="row">
                        <ToastContainer toastClassName="custom-info" hideProgressBar={true} position="bottom-center" />
                    </div>

                    <ErrorModal
                        title={'Archive Flight Not Permitted'}
                        message={'This flight has an Open schedule with additional documentation. Please review.'}
                        headerStyleClass={''}
                        isOpen={this.props.isShowArchiveWarnning}
                        onClose={() => { this.props.ShowArchiveWarning() }}
                    />
                    <ConfirmationModal
                        title={'Archive Flight?'}
                        message={
                            (this.props.selectedTail && (this.props.selectedTail.defects.length > 0 || this.props.selectedTail.tail.mxrequests.length > 0))                               
                                ? 'This flight has an Open schedule. Are you sure?' : 'Do you want to archive this flight?'}
                        confirmButtonText={'YES, ARCHIVE'}
                        isOpen={this.props.showArch}
                        onClose={() => { this.props.ShowArchiveConfirmation(); }}
                        onConfirm={() => { this.props.ArchiveFlight(); }}
                    />
                    <ConfirmationModal
                        title={'Unarchive Flight?'}
                        message={'Do you want to unarchive this flight?'}
                        confirmButtonText={'YES, UNARCHIVE'}
                        isOpen={this.props.showUnarchivePopup}
                        onClose={() => { this.props.ShowUnarchiveConfirmation(); }}
                        onConfirm={() => { this.props.UnArchiveFlightStatus() }}
                    />

                    <ErrorModal
                        title={'Replace Flight Not Permitted'}
                        message={'This flight has an Open schedule with additional documentation. Please review.'}
                        headerStyleClass={''}
                        isOpen={this.props.isShowReplaceWarnning}
                        onClose={() => { this.props.ShowReplaceWarning() }}
                    />

                    <ConfirmationModal
                        title={'Replace Flight?'}
                        message={
                            (this.props.selectedTail && (this.props.selectedTail.defects.length > 0 || this.props.selectedTail.tail.mxrequests.length > 0))
                                ? 'This flight has an Open schedule. Are you sure?' : 'Do you want to Replace this flight?'}
                        confirmButtonText={'YES, REPLACE'}
                        isOpen={this.props.showReplace}
                        onClose={() => { this.props.ShowReplaceConfirmation(); }}
                        onConfirm={() => { this.props.FetchCorrections(); }}
                    />

                    <DeleteModal
                        message={'This record will be deleted'}
                        isOpen={this.props.showDeleteMxrConfirmation}
                        onConfirm={this.props.DeleteMxR}
                        onClose={this.props.CloseDeleteMxrConfirmation}
                    />

                    <DeleteModal
                        message={'This record will be deleted'}
                        isOpen={this.props.showDeletePartConfirmation}
                        onConfirm={this.props.DeleteParts}
                        onClose={this.props.CloseDeletePartConfirmation}
                    />

                    <DeleteModal
                        message={'This record will be deleted'}
                        isOpen={this.props.showDeleteNoteConfirmation}
                        onConfirm={this.props.DeleteNotes}
                        onClose={this.props.CloseDeleteNoteConfirmation}
                    />

                    <DeleteModal
                        message={'This record will be deleted'}
                        isOpen={this.props.showDeleteMxNoteConfirmation}
                        onConfirm={this.props.DeleteMxNotes}
                        onClose={this.props.CloseDeleteMxNoteConfirmation}
                    />

                    <Modal centered={true} className="partsModalXL" isOpen={this.props.showCorrection}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.ShowCorrectTool(); }}><IoCloseOutline /></div>

                                </div>
                            </div>

                            <div className="d-flex mb-3">
                                <div className="p-2">Replace Flights</div>
                                <div className="p-2"><img src={lineLogo} /></div>
                                <div className="p-2 lightText center-alignment">{this.props.correctionTails && (this.props.correctionTails.length - 1) + " tails"}</div>
                            </div>

                        </ModalHeader>
                        <ModalBody>
                            { /*replace modal*/}
                        {this.props.selectedTail &&
                            <div className={this.props.seletedCorrectionTail ? "left-right-padding-55" : ""}>
                            <div className={this.props.seletedCorrectionTail ? "flex flex-space-between w-100" : "flex flex-center flex-space-between w-100"}>
                                <div className="tailsInstance2" >
                                    <div className="otherContent2">
                                        <div className="flex flex-space-between">
                                            <p className="titleTailInstance2">{this.props.selectedTail.tail.airCrafts.tail}</p>
                                            <div className="current-tag">Current</div>
                                        </div>
                                        <section>
                                        <div className="row">
                                            <div className="col tailNumberInstance">{this.props.selectedTail.tail.airCrafts.customer.companyName}&nbsp;{this.props.selectedTail.tail.airCrafts.acType}</div>
                                            <div className="col middleHeaderInstance">GT: {this.props.selectedTail.tail.groundTimeDisplayValue}</div>
                                                    <div className="col rightHeaderInstance">Tasks:<b>{
                                                        this.props.selectedTail.defects.length + this.props.selectedTail.tail.mxrequests.filter((mx: any) => mx.mxRequestPlanningStatusId == 1).length
                                                    }</b></div>
                                        </div>
                                        <div className="row">
                                            <div className="col tailNumberInstance">&nbsp;</div>
                                            <div className="col middleHeaderInstance">&nbsp;</div>
                                            <div className="col rightHeaderInstance">&nbsp;</div>
                                        </div>
                                        <div className="row textHeaderInstance">
                                            <div className="col">{this.props.selectedTail.tail.airlineCode}{this.props.selectedTail.tail.flightNo} {this.props.selectedTail.tail.departingStationName}</div>
                                                    <div className="col padding-left-13"><b>Arr : {this.props.selectedTail.tail.arrivalDisplayTime}</b></div>
                                                    <div className="col">{this.props.selectedTail.tail.arrivalDisplayDate}</div>
                                        </div>
                                        <div className="row textHeaderInstance">
                                            <div className="col">{this.props.selectedTail.tail.departingAirlineCode}{this.props.selectedTail.tail.departingFlightNo} {this.props.selectedTail.tail.arrivingStationName}</div>
                                                    <div className="col padding-left-13"><b>Dep : {this.props.selectedTail.tail.departureDisplayTime}</b></div>
                                            <div className="col">{this.props.selectedTail.tail.departureDisplayDate}</div>
                                        </div>
                                        </section>
                                    </div>
                                </div>
                                {this.props.seletedCorrectionTail &&
                                    <div className="center-alignment">

                                                <img src={dtcLogo} width="100" />

                                            </div>
                                        }
                                        {this.props.seletedCorrectionTail &&

                                            <div className="tailsInstance2" >
                                                <div className="otherSelected">
                                                    <div className="flex flex-space-between">
                                                        <p className="titleTailInstance2">{this.props.seletedCorrectionTail.tail.airCrafts.tail}</p>
                                                        <div className="select-tag">Selected</div>
                                                    </div>
                                                    <section>
                                                        <div className="row">
                                                            <div className="col tailNumberInstance">{this.props.seletedCorrectionTail.tail.airCrafts.customer.companyName}&nbsp;{this.props.seletedCorrectionTail.tail.airCrafts.acType}</div>
                                                            <div className="col middleHeaderInstance">GT: {this.props.seletedCorrectionTail.tail.groundTimeDisplayValue}</div>
                                                            <div className="col rightHeaderInstance">Tasks: <b>
                                                                {this.props.seletedCorrectionTail.defects.length + this.props.seletedCorrectionTail.tail.mxrequests.filter((mx: any) => mx.mxRequestPlanningStatusId == 1).length
                                                                 }
                                                            </b></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col tailNumberInstance">&nbsp;</div>
                                                            <div className="col middleHeaderInstance">&nbsp;</div>
                                                            <div className="col rightHeaderInstance">&nbsp;</div>
                                                        </div>
                                                        <div className="row textHeaderInstance">
                                                            <div className="col">{this.props.seletedCorrectionTail.tail.airlineCode}{this.props.seletedCorrectionTail.tail.flightNo} {this.props.seletedCorrectionTail.tail.departingStationName}</div>
                                                            <div className="col"><b>Arr : {this.props.seletedCorrectionTail.tail.arrivalDisplayTime}</b></div>
                                                            <div className="col">{this.props.seletedCorrectionTail.tail.arrivalDisplayDate}</div>
                                                        </div>
                                                        <div className="row textHeaderInstance">
                                                            <div className="col">{this.props.seletedCorrectionTail.tail.departingAirlineCode}{this.props.seletedCorrectionTail.tail.departingFlightNo} {this.props.seletedCorrectionTail.tail.arrivingStationName}</div>
                                                            <div className="col"><b>Dep : {this.props.seletedCorrectionTail.tail.departureDisplayTime}</b></div>
                                                            <div className="col">{this.props.seletedCorrectionTail.tail.departureDisplayDate}</div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className=" flex flex-center w-100 middleHeaderInstance" >
                                        <div><br />
                                            {this.props.correctionTails && this.props.correctionTails.length > 1 ?
                                                "Select a Tail card to replace" : "No Tail card available to replace"}
                                        </div>
                                    </div>
                                </div>}

                            <div className="tailsDiv3">
                                {
                                    this.props.correctionTails.map((tailRecord: any) => {
                                        if (this.props.selectedTail && this.props.selectedTail.tail.id === tailRecord.tail.id) {
                                            return ""
                                        }
                                        return <div key={tailRecord.tail.id} className="tailsInstance2" onClick={() => { this.props.SelectCorrectionTail(tailRecord); }}>

                                            <div className={this.props.seletedCorrectionTail && this.props.seletedCorrectionTail.tail.id === tailRecord.tail.id ? "otherSelected" : "otherContent"}>
                                                <div className="flex flex-space-between">
                                                    <p className="titleTailInstance2">{tailRecord.tail.airCrafts.tail}</p>

                                                </div>
                                                <section>
                                                    <div className="row">
                                                        <div className="col tailNumberInstance">{tailRecord.tail.airCrafts.customer.companyName}&nbsp;{tailRecord.tail.airCrafts.acType}</div>
                                                        <div className="col middleHeaderInstance">GT: {tailRecord.tail.groundTimeDisplayValue}</div>
                                                        <div className="col rightHeaderInstance">Tasks: <b>
                                                            {tailRecord.defects.length + tailRecord.tail.mxrequests.filter((mx: any) => mx.mxRequestPlanningStatusId == 1).length}
                                                        </b></div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col tailNumberInstance">&nbsp;</div>
                                                        <div className="col middleHeaderInstance">&nbsp;</div>
                                                        <div className="col rightHeaderInstance">&nbsp;</div>
                                                    </div>
                                                    <div className="row textHeaderInstance">
                                                        <div className="col">{tailRecord.tail.airlineCode}{tailRecord.tail.flightNo} {tailRecord.tail.departingStationName}</div>
                                                        <div className="col"><b>Arr : {tailRecord.tail.arrivalDisplayTime}</b></div>
                                                        <div className="col">{tailRecord.tail.arrivalDisplayDate}</div>
                                                    </div>
                                                    <div className="row textHeaderInstance">
                                                        <div className="col">{tailRecord.tail.departingAirlineCode}{tailRecord.tail.departingFlightNo} {tailRecord.tail.arrivingStationName}</div>
                                                        <div className="col"><b>Dep : {tailRecord.tail.departureDisplayTime}</b></div>
                                                        <div className="col">{tailRecord.tail.departureDisplayDate}</div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    }
                                    )
                                }</div>


                        </ModalBody>
                        <ModalFooter>
                            <button className="round-empty-button" type="reset" onClick={() => { this.props.ShowCorrectTool(); }}>CANCEL</button>
                            <span>&nbsp;</span>
                            <button className="round-fill-button border-0" onClick={() => { this.props.ReplaceFlight(); }}>
                                SAVE
                            </button>

                        </ModalFooter>
                    </Modal>


                    <Modal centered={true} className="partsModal" isOpen={this.props.showAddMx}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.CloseAddMxR(); }}><IoCloseOutline /></div>

                                </div>
                            </div>
                            {this.props.selectedTail &&
                                <div className="header-title-popup text-align-center">
                                    {this.props.selectedMxR.id === 0 ? "Add New MX Request" : "Edit MX Request"}
                                </div>
                            }

                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={this.props.SaveMxR}
                                initialValues={this.props.selectedMxR}
                                validate={values => {
                                    let errors = {} as any;
                                    if (!values.description) {
                                        errors.description = 'Required'
                                    }
                                    if (!values.mxRequestTypesValues || values.mxRequestTypesValues.value === 0) {
                                        errors.mxRequestTypesValues = 'Required'
                                    }
                                    if (!values.mxRequestStatusValues || values.mxRequestStatusValues.value === 0) {
                                        errors.mxRequestStatusValues = "Required"
                                    }
                                    return errors
                                }}
                                render={({ handleSubmit, form, submitting, pristine, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="partsForm">
                                            <div className="">
                                                <div>
                                                    <label>Type<span>*</span></label>

                                                    <Field
                                                        name="mxRequestTypesValues"
                                                        component={ReactSelectAdapter}


                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <ReactSelectAdapter options={this.props.mxTypes.map((typ: PlanningStore.MxRequestTypes) => {
                                                                    return { value: typ.id, label: typ.requestText };
                                                                }
                                                                )}  {...input}
                                                                />
                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>




                                                </div>
                                            </div>
                                            <div>&nbsp;</div>
                                            <div className="">
                                                <div>
                                                    <label>Reference # (optional)</label>
                                                    <Field name="refNumber"
                                                        className="form-control w-100"
                                                        placeholder="Enter reference #"
                                                        component="input">
                                                    </Field>
                                                </div>
                                            </div>
                                            <div>&nbsp;</div>
                                            <div className="">
                                                <div>
                                                    <label>Planning Status<span>*</span></label>
                                                    <Field
                                                        name="mxRequestStatusValues"
                                                        component={ReactSelectAdapter}
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <ReactSelectAdapter options={this.props.mxStatus.map((typ: PlanningStore.MxRequestPlanningStatus) => {
                                                                    return { value: typ.id, label: typ.planningStatus };
                                                                }
                                                                )}  {...input}
                                                                />
                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div>&nbsp;</div>
                                            <div className="">
                                                <div>
                                                    <Field
                                                        name="description"
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Description<span>*</span></label>
                                                                <textarea className="form-control" rows={5}  {...input}
                                                                    placeholder="Enter your description here..." />
                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>

                                            <div>&nbsp;</div>
                                            <div>
                                                <div>
                                                    <input accept=".jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.gif,.JPG,.PNG,.PDF,.DOC,.DOCX,.XLS,.XLSX,.GIF" type="file" multiple
                                                        onChange={this.onFileChange} onClick={this.onInputClick} className="custom-file-input" id="files" aria-describedby="fileHelp" />
                                                    <label className="custom-file-label" htmlFor="files">
                                                        Files (optional)&nbsp;&nbsp;
                                                    </label>
                                                </div>
                                                {this.renderThumbnails()}
                                            </div>
                                            <div>&nbsp;</div>

                                            <div className="text-right-align">

                                                <button className="round-empty-button  cancel-popover-button" type="reset" onClick={() => { this.props.CloseAddMxR(); }}>CANCEL</button>
                                                <span>&nbsp;</span>
                                                <button className={this.props.selectedMxR.id === 0 ? "round-fill-button border-0 save-popover-button save-button-width-add" : "round-fill-button border-0 save-popover-button"} type="submit" disabled={submitting}>
                                                    {this.props.selectedMxR.id === 0 ? "SAVE" : "SAVE CHANGES"}
                                                </button>

                                            </div>
                                        </div>
                                    </form>
                                )}
                            />
                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                    </Modal>

                    <Modal centered={true} className="partsModal" isOpen={this.props.showAddParts}>
                        <ModalHeader className="modalHeader">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.CloseAddParts(); }}><IoCloseOutline /></div>

                                </div>
                            </div>
                            {this.props.selectedTail &&
                                <span>
                                    {this.props.selectedPart.id === 0 ? "Add " : "Edit "}
                                    your parts for {this.props.selectedTail.tail.airCrafts.tail}
                                </span>
                            }

                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={this.props.SavePart}
                                initialValues={this.props.selectedPart}
                                validate={values => {
                                    let errors = {} as any;
                                    if (!values.partNumber) {
                                        errors.partNumber = 'Required'
                                    }
                                    if (!values.partName) {
                                        errors.partName = 'Required'
                                    }

                                    if (!values.qtyRequested && values.qtyRequested != "0") {
                                        errors.qtyRequested = '0 or more'
                                    }

                                    if (!values.qtyAvailable && values.qtyAvailable != "0") {
                                        errors.qtyAvailable = '0 or more'
                                    }
                                    return errors
                                }}
                                render={({ handleSubmit, form, submitting, pristine, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="partsForm">
                                            <div className="flex flex-left">
                                                <div>
                                                    <Field
                                                        name="partNumber"
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Part Number (P/N)</label>
                                                                <input className="form-control" maxLength={20} {...input} type="text" placeholder="" />
                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                                <div>
                                                    &nbsp;&nbsp;&nbsp;
                                                </div>
                                                <div>
                                                    <Field
                                                        name="partName"
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Part Name</label>
                                                                <input className="partNameBox form-control" maxLength={30} {...input} type="text" placeholder="" />
                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div>&nbsp;</div>
                                            <div className="flex flex-left">


                                                <div>
                                                    <Field
                                                        name="qtyRequested"
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Qty Requested</label>
                                                                <input className="form-control" maxLength={4} min={0} max={9999} {...input} type="number"
                                                                    {...input} />
                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                                <div>
                                                    &nbsp;&nbsp;&nbsp;
                                                </div>
                                                <div>
                                                    <Field
                                                        name="qtyAvailable"
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Qty Available</label>
                                                                <input className="form-control" maxLength={4} min={0} max={9999} {...input} type="number" placeholder="" />
                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>

                                                </div>
                                            </div>
                                            <div>&nbsp;</div>
                                            <div className="flex flex-space-between">
                                                <div className="flex-grow-1">
                                                    <label>Description</label>
                                                    <Field
                                                        className="form-control"
                                                        name="noteDesc" component="textarea" placeholder="" />
                                                </div>
                                            </div>
                                            <div>&nbsp;</div>
                                            <div>

                                                <button className="round-empty-button" type="reset" onClick={() => { this.props.CloseAddParts(); }}>CANCEL</button>
                                                <span>&nbsp;</span>
                                                <button className="round-fill-button border-0 margin-left-24" type="submit" disabled={submitting}>
                                                    {this.props.selectedPart.id === 0 ? "SAVE" : "SAVE CHANGES"}
                                                </button>

                                            </div>
                                        </div>
                                    </form>
                                )}
                            />
                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                    </Modal>


                    <Modal centered={true} isOpen={this.props.showAddNotes} >
                        <ModalHeader className="modalHeader">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.CloseAddNotes(); }}><IoCloseOutline /></div>

                                </div>
                            </div>
                            {this.props.selectedTail &&
                                <span>
                                    {this.props.selectedNote.id === 0 ? "Add " : "Edit "}
                                    your notes for {this.props.selectedTail.tail.airCrafts.tail}
                                </span>
                            }

                        </ModalHeader>
                        <ModalBody>
                            <div className="modalLabel">Notes</div>
                            <textarea rows={4}
                                defaultValue={this.props.selectedNote.noteText}
                                onChange={this.props.UpdateNotes}
                                placeholder="Enter your notes here"
                                className="form-control w-100" />
                            <div>&nbsp;
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="round-empty-button" onClick={() => { this.props.CloseAddNotes(); }}>
                                CANCEL
                            </div>
                            <div className="round-fill-button model-btn-margin" onClick={() => { this.props.SaveNote(); }}>
                                {this.props.selectedNote.id === 0 ? "SAVE" : "SAVE CHANGES"}
                            </div>
                        </ModalFooter>
                    </Modal>

                    <Modal centered={true} className="partsModal" isOpen={this.props.showPrintSchedule}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.ClosePrintSchedule(); }}><IoCloseOutline /></div>
                                </div>
                            </div>
                            <span>
                                Print Schedule
                            </span>
                        </ModalHeader>
                        <ModalBody>

                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className="w-50">
                                            {this.props.printList.map((result: any) => {
                                                return (
                                                    <>
                                                        <p>
                                                            <input type="radio" name="radio-group" className="" id={"radio_" + result.id}
                                                                value={result.id}
                                                                checked={this.props.selectedPrintId === result.id}
                                                                onClick={() => { this.props.SelectPrintSchedule(result.id); }}
                                                            />
                                                            <label htmlFor={"radio_" + result.id} className="filter-checkbox-label bold-text">&nbsp;{result.requestText}</label>
                                                            {result.id == 1 && " (Full Page - Default)"}
                                                        </p>


                                                    </>
                                                )
                                            })
                                            }
                                        </td>
                                        <td className="w-50">
                                            <div>&nbsp;</div>
                                            <br />
                                            <div style={this.props.selectedPrintId != 2 ? { pointerEvents: "none", opacity: "0.7" } : {}}>
                                                <ReactSelectAdapterFlight
                                                    onChange={this.OnChangeFlight.bind(this)}
                                                    value={(this.props.selectedTail != null && this.props.selectedPrintId == 2 && this.props.selectedFlightId == 0) ?
                                                        { value: this.props.selectedTail.tail.id, label: this.props.selectedTail.tail.tailDetails }
                                                        : (this.props.selectedTail != null && this.props.selectedPrintId == 2 && this.props.selectedFlightId > 0) ?
                                                            { value: this.props.selectedFlightId, label: this.props.selectedFlightDetails }
                                                            : { value: "0", label: "Select Flight" }}
                                                    options={this.props.tailRecords != null && this.props.tailRecords.map((typ: any) => {
                                                        return { value: typ.tail.id, label: typ.tail.tailDetails };
                                                    }
                                                    )}
                                                />
                                            </div>
                                            <br />
                                            <div style={this.props.selectedPrintId != 3 ? { pointerEvents: "none", opacity: "0.7" } : {}}>
                                                <ReactSelectAdapterWithoutZindex
                                                    onChange={this.OnChangeFleet.bind(this)}
                                                    value={this.props.selectedPrintId == 3 ? { value: this.props.selectedFleetId, label: this.props.selectedFleetId } : { value: "0", label: "Select Fleet" }}
                                                    options={this.props.allFleets != null && this.props.allFleets.map((fleet: any) => {
                                                        return { value: fleet, label: fleet };
                                                    }
                                                    )}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalBody>
                        <ModalFooter>
                            <div className="round-empty-button-popup" onClick={() => { this.props.ClosePrintSchedule(); }}>
                                CANCEL
                            </div>
                            <div className="round-fill-button-popup model-btn-margin" onClick={() => { this.printReport(); }}>
                                DOWNLOAD
                            </div>

                        </ModalFooter>
                    </Modal>

                    <Modal centered={true} className="smallModal diff-hours-modal" isOpen={this.props.showFileValidation}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.CloseImageValidError(); }}><IoCloseOutline /></div>

                                </div>
                            </div>
                            <div className="flex flex-center w-100">
                                <span>INVALID FILES</span>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="flex flex-center w-100">
                                <div className="flex flex-center w-100 text-center">{this.props.errorMessage}</div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="flex flex-center w-100">
                                <div className="round-fill-button border-0 diff-hours-ok" onClick={() => { this.props.CloseImageValidError(); }}>OK</div>
                            </div>

                        </ModalFooter>
                    </Modal>

                    <Modal centered={true} isOpen={this.props.showMxAddNote} >
                        <ModalHeader className="modalHeader">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.CloseMxAddNotes(); }}><IoCloseOutline /></div>

                                </div>
                            </div>
                            {this.props.selectedTail &&
                                <div className="text-center">
                                    {this.props.selectedMxNote.id === 0 ? "Add " : "Edit "} your notes
                                    <br />
                                    <span style={{ fontSize: "medium" }}>
                                        MX Request Ref # {this.props.SelectedMxRequestRef}
                                    </span>
                                </div>
                            }

                        </ModalHeader>
                        <ModalBody>
                            <div className="modalLabel">Notes</div>
                            <textarea rows={4}
                                defaultValue={this.props.selectedMxNote.note}
                                onChange={this.props.UpdateMxNotes}
                                placeholder="Enter your notes here"
                                className="form-control w-100" />
                            <div>&nbsp;
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="round-empty-button" onClick={() => { this.props.CloseMxAddNotes(); }}>
                                CANCEL
                            </div>
                            <div className="round-fill-button model-btn-margin" onClick={() => { this.props.SaveMxNote(); }}>
                                {this.props.selectedNote.id === 0 ? "SAVE" : "SAVE CHANGES"}
                            </div>
                        </ModalFooter>
                    </Modal>

                    <Modal className="" centered={true} isOpen={this.props.imageOpen}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.selectImage(null); }}
                                    ><IoCloseOutline /></div>

                                </div>
                            </div>

                            <div className="flex flex-center w-100">
                                {this.props.selectedImage && this.props.selectedImage.fileName}
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <img src={this.props.selectedImage && this.props.selectedImage.imageUri} width="400px" style={{ margin: 'auto', display: 'block' }} />
                            </div>
                        </ModalBody>
                        <ModalFooter >
                            <br />
                            <div className="flex flex-button w-100">
                                <div className="round-empty-button-popup" onClick={() => { this.props.selectImage(null); }}   >
                                    CANCEL
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal >

                    <div className="col-12 outerPlanning planing-Container">
                        <div ref={this.myRef} onScroll={(event) => { this.handleScroll() }} className={!this.props.isUserSelected ? "showIPFlex tailsDiv" : "hideIPFlex tailsDiv"}>
                            <div className="planTitle planningTitle">
                                Plan Workload
                            </div>

                            <div className="planTitle">
                                &nbsp;
                            </div>

                            <div className="planTitle flex">
                                <div>

                                    <DatePicker
                                        selected={this.props.selectedDate}
                                        onChange={(date: Date) => this.props.SelectDat(date, true)}
                                        minDate={new Date()}
                                        maxDate={new Date(new Date().setDate(new Date().getDate() + 2))}
                                        customInput={(<this.CustomInput value='' onClick='' />)}
                                    />

                                </div>
                                <div className="showInMax filter-width-planning">
                                    <Button id="mypopover" style={{ padding: "0px" }} className="filter-height-planning btn-width-planning  buttonFilter btnFilter leftMargin backGroundWhite" onClick={() => { this.props.ToggleFilterSortPopup(); }} >
                                        <IoFunnelOutline />

                                        <span>&nbsp;&nbsp;Filter or Sort by&nbsp;</span>
                                        <IoChevronUp className="planningFilterChevrondown" />
                                    </Button>
                                    <UncontrolledPopover
                                        placement="bottom"
                                        target="mypopover"
                                        className="popOver-filter positionFilter bottom-0"
                                        isOpen={this.props.isFilterSortPopupOpen}
                                        trigger="legacy"
                                    >
                                        <div className="flex flex-end w-100">
                                            <div className="filter-close-style">
                                                <div className="filter-crossButton"
                                                    onClick={() => { this.CloseFilterPopup(); }}>
                                                    <IoCloseOutline /></div>

                                            </div>
                                        </div>

                                        <div className="workload-main-heading-filter ">
                                            Planning Filters
                                        </div>
                                        <br />
                                        <PopoverHeader className="workload-filter-popover-header mb-3">
                                            SORT BY
                                        </PopoverHeader>
                                        <PopoverBody>
                                            <Select
                                                options={sortByValues}
                                                defaultValue={{ value: 0, label: "Select" }}
                                                styles={customStyles}
                                                onChange={event => { this.AssignFilterValues(event, 'sort') }}
                                                value={sortByValues.filter(option => option.value === this.props.filterValues.sortValue)[0]
                                                }
                                            />
                                            <br />
                                            <div className="checkbox-wrapper">
                                                <input
                                                    name="ascending"
                                                    type="checkbox" className="filter-checkbox ml-0"
                                                    checked={this.props.filterValues.sortType === 'asc'}
                                                    onChange={event => { this.AssignFilterValues(event, 'asc') }}
                                                />
                                                <span className="filter-checkbox-label w-50">Ascending</span>
                                                <img src={ascending} />
                                            </div>
                                            <br />
                                            <div className="checkbox-wrapper">
                                                <input
                                                    name="descending"
                                                    type="checkbox" className="filter-checkbox ml-0"
                                                    checked={this.props.filterValues.sortType === 'desc'}
                                                    onChange={event => { this.AssignFilterValues(event, 'desc') }}
                                                />
                                                <span className="filter-checkbox-label w-50">Descending</span>
                                                <img src={descending} />
                                            </div>
                                            <br />
                                        </PopoverBody>
                                        <PopoverHeader className="workload-filter-popover-header mb-3">
                                            FILTER BY
                                        </PopoverHeader>
                                        <PopoverBody>
                                            <div className="checkbox-wrapper">
                                                <input
                                                    name="isArchivedFlights"
                                                    type="checkbox" className="filter-checkbox ml-0"
                                                    checked={this.props.filterValues.showArchivedFlights}
                                                    onChange={event => { this.AssignFilterValues(event, 'archive') }}
                                                />
                                                <span className="filter-checkbox-label">Archived flights</span>
                                            </div>
                                            <br />
                                        </PopoverBody>
                                        <br />
                                        <PopoverBody className="mb-3">

                                            <button className="round-empty-button cancel-popover-button" type="reset" onClick={() => { this.CloseFilterPopup(); }}>CANCEL</button>
                                            <span>&nbsp;</span>
                                            <button className="round-fill-button border-0 save-popover-button" type="button" onClick={() => { this.FilterSortData() }}>
                                                SAVE CHANGES
                                            </button>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </div>
                            </div>
                            {this.props.tailRecords && this.renderTailsTable()}

                            {this.props.isTop &&
                                <div className="tailsdiv" title="Back to top">
                                    <IoArrowUpOutline className="float"
                                        onClick={(event) => { this.OnBackToTop() }} />
                                </div>
                            }
                        </div>
                        {!this.props.isLoading &&
                            <div className={this.props.isUserSelected ? "showIP detailContent" : "hideIP detailContent"}>
                                {this.props.selectedTail &&

                                    <div className="planTitle planningTitle">
                                        <div className="w-100 text-right-align padding-right-16">
                                            <span className="underConstruction" >Under Construction</span>
                                            <button className={(this.props.filterValues.showArchivedFlights ? "round-empty-button-disable " : "") + "round-empty-button print-schedule-style text-center"} onClick={() => { this.props.ShowPrintSchedule() }}>
                                                PRINT SCHEDULE
                                            </button>
                                        </div>
                                        <div>
                                            <span className="showMx w-70"

                                            ><FaChevronLeft width="13" onClick={() => { this.props.DeSelectTail(); }} />


                                                &nbsp;Plan Items for {this.props.selectedTail.tail.airCrafts.tail}
                                                <UncontrolledDropdown className="contextMenuHeader">
                                                    <DropdownToggle nav>
                                                        <FaEllipsisH />
                                                    </DropdownToggle>
                                                    <DropdownMenu >
                                                        <DropdownItem className="menuItemMx" onClick={event => { this.props.ShowAddMxR() }}>+ Add new MX item</DropdownItem>
                                                        <DropdownItem className="menuItemMx" onClick={() => this.navigateToWorksheet(this.props.selectedTail.tail.id)} >View Worksheet</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </span>

                                        </div>
                                        <div className="showInMax">
                                            <div className="row">
                                                <div className="w-70 margin-left-16">
                                                    &nbsp;Plan Items for {this.props.selectedTail.tail.airCrafts.tail}

                                                </div>

                                            </div>
                                        </div>
                                        <br /> &nbsp;
                                    </div>}
                                {this.props.selectedTail
                                    &&
                                    this.props.selectedTail.tail
                                    &&
                                    this.props.selectedTail.tail.mxrequests
                                    &&
                                    this.props.selectedTail.tail.mxrequests.length > 0
                                    &&
                                    (<div className="hide-from-tablet">
                                        {this.renderMxrequests()}
                                    </div>
                                    )


                                }
                                {this.props.selectedTail
                                    &&
                                    this.props.selectedTail.tail
                                    &&
                                    this.props.selectedTail.tail.mxrequests
                                    &&
                                    this.props.selectedTail.tail.mxrequests.length > 0
                                    &&
                                    (
                                        <div className="hide-in-big-screen show-in-tablet">
                                            {this.renderMxrequestsInTablet()}
                                        </div>
                                    )

                                }

                                {this.props.selectedTail
                                    &&
                                    this.renderMddrTable()
                                }

                            </div>}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderPlanningParts(parts: any) {

        if (parts != null && parts.length > 0) {
            return (
                <>
                    <div className="partsOuterDiv">
                        <div>&nbsp;</div>

                        <p className="notesTitle">Parts:</p>
                        <div>
                            <div className="flex flex-space-between">
                                <div className="planningSubTitle w-30">P/N</div>
                                <div className="planningSubTitle w-50">Description</div>
                                <div className="planningSubTitle w-10">Qty</div>
                                <div className="w-5">&nbsp;</div>
                            </div>

                        </div>
                        {parts.map((part: any) =>
                            <div key={part.id}>
                                <div className="flex flex-space-between">
                                    <div className="notesText w-30">{part.partNumber}</div>
                                    <div className="notesText w-50">{part.description}</div>
                                    <div className="notesText w-10">{part.qtyRequested}</div>
                                    <div className="w-5">

                                        <UncontrolledDropdown className="contextMenuHeader">
                                            <DropdownToggle nav>
                                                <FaAngleDown />
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem key={part.id} onClick={event => { this.props.EditParts(event, part) }} >Edit</DropdownItem>
                                                <DropdownItem onClick={event => { this.props.ShowDeletePartConfirmation(part) }} >Delete</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>

                            </div>
                        )}
                    </div>
                </>
            );
        }

        return "";
    }

    private renderPlanningNotes(notes: any) {



        if (notes != null && notes.length > 0) {
            return (
                <>
                    {
                        <div className="notesOuterDiv">
                            <div>&nbsp;</div>

                            <p className="notesTitle">Planning Notes:</p>


                            {notes.map((note: any) =>
                                <div key={note.id}>

                                    <div className="flex flex-space-between flex-align-end">
                                        <div className="planningSubTitle">{note.lastUpdateAtDisplay}</div>

                                        <UncontrolledDropdown className="contextMenuHeader">
                                            <DropdownToggle nav className="notesAdddedBy">
                                                Added by {note.user.userName}
                                                <FaAngleDown />
                                            </DropdownToggle>
                                            {note.isEditable === true &&
                                                <DropdownMenu right>
                                                    <DropdownItem key={note.id} onClick={event => { this.props.EditNotes(event, note) }} >Edit</DropdownItem>
                                                    <DropdownItem onClick={event => { this.props.ShowDeleteNoteConfirmation(note) }}>Delete</DropdownItem>
                                                </DropdownMenu>
                                            }
                                            {note.isEditable !== true &&
                                                <DropdownMenu right>
                                                    <DropdownItem key={note.id} >No Applicable Action</DropdownItem>
                                                </DropdownMenu>
                                            }
                                        </UncontrolledDropdown>


                                    </div>
                                    <div className="notesText">
                                        {note.noteText}
                                    </div>
                                    <div>&nbsp;</div>
                                </div>
                            )}
                        </div>
                    }

                </>
            );
        }
        else {
            return "";
        }
    }

    private renderMxrequests() {
        return (
            <>
                <div className="outerDivMxR">
                    <div className="titleMxR">
                        MX Requests:
                    </div>
                    {this.props.selectedTail.tail.mxrequests.map((req: any) =>
                        <div>
                            <div className="flex flex-space-between headerMxR">
                                <div className="planningSubTitle w-20">Ref#</div>
                                <div>&nbsp;</div>

                                <div className="planningSubTitle w-30">Type</div>
                                <div className="planningSubTitle w-30">Planning</div>

                                <div className="planningSubTitle w-20" style={{ textAlign: "center" }}>Actions</div>
                            </div>

                            <div key={req.id} className="rowMxR mb-2">
                                <div className="flex flex-space-between ">
                                    <div className="w-20">{req.refNumber}</div>
                                    <div>&nbsp;</div>
                                    <div className="w-30">{req.mxRequestTypes.requestText}</div>
                                    <div className="w-30">
                                        <span className={`statusPad-auto ${req.mxRequestPlanningStatusId === 1 ? "statusPadPs" : req.mxRequestPlanningStatusId === 2 ? "statusPad4" : "statusPad2"}`}>
                                            {req.mxrequestPlanningStatus && req.mxrequestPlanningStatus.planningStatus.toUpperCase()}
                                        </span>

                                    </div>
                                    <div className="w-20 text-center">
                                        <IoCreateOutline className="cursor-pointer" onClick={event => { this.props.EditMxR(event, req) }} />
                                        <IoTrashOutline className="cursor-pointer margin-left-16" onClick={event => { this.props.ShowDeleteMxrConfirmation(req) }} />
                                    </div>
                                </div>
                                <div className="buttonOuterDiv flex flex-end">
                                    <div className="round-empty-button" onClick={() => { this.props.ShowMxAddNotes(req.id, req.refNumber) }}>
                                        ADD NOTES
                                    </div>
                                </div>
                                <div className="flex flex-space-between">
                                    {this.renderMxNotes(req.mxNotes.filter((n: any) => n.sourceScreen == 1), req.refNumber)
                                    }

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }

    private renderMxrequestsInTablet() {
        return (
            <>
                <div className="outerDivMxR">
                    <div className="titleMxR">
                        MX Requests:
                    </div>
                    {this.props.selectedTail.tail.mxrequests.map((req: any) =>
                        <div>
                            <div className="flex flex-space-between headerMxR">
                                <div className="planningSubTitle w-15">Ref#</div>
                                <div>&nbsp;</div>

                                <div className="planningSubTitle w-35">Type</div>
                                <div className="planningSubTitle w-30">Planning</div>

                                <div className="planningSubTitle w-20" style={{ textAlign: "center" }}>Actions</div>
                            </div>

                            <div key={req.id} className="rowMxR mb-2">
                                <div className="flex flex-space-between ">
                                    <div className="w-15">{req.refNumber}</div>
                                    <div>&nbsp;</div>
                                    <div className="w-35">{req.mxRequestTypes.requestText}</div>
                                    <div className="w-30">
                                        <span className={`statusPad-auto ${req.mxRequestPlanningStatusId === 1 ? "statusPadPs" : req.mxRequestPlanningStatusId === 2 ? "statusPad4" : "statusPad2"} statusPad-tab`}>
                                            {req.mxrequestPlanningStatus && req.mxrequestPlanningStatus.planningStatus.toUpperCase()}
                                        </span>

                                    </div>
                                    <div className="w-20 text-center">
                                        <IoCreateOutline className="cursor-pointer" onClick={event => { this.props.EditMxR(event, req) }} />
                                        <IoTrashOutline className="cursor-pointer margin-left-16" onClick={event => { this.props.ShowDeleteMxrConfirmation(req) }} />
                                    </div>
                                </div>
                                <div className="buttonOuterDiv flex flex-end">
                                    <div className="round-empty-button" onClick={() => { this.props.ShowMxAddNotes(req.id, req.refNumber) }}>
                                        ADD NOTES
                                    </div>
                                </div>
                                <div className="flex flex-space-between">
                                    {this.renderMxNotes(req.mxNotes.filter((n: any) => n.sourceScreen == 1), req.refNumber)
                                    }

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }

    private renderMxNotes(notes: any, ref: string) {
        if (notes != null && notes.length > 0) {
            return (
                <>
                    {
                        <div className="notesOuterDiv">
                            <div>&nbsp;</div>

                            <p className="notesTitle">MX Notes for Reference # {ref}:</p>


                            {notes.map((note: any) =>
                                <div key={note.id}>

                                    <div className="flex flex-space-between flex-align-end">
                                        <div className="planningSubTitle">{note.lastUpdateAtDisplay}</div>

                                        <UncontrolledDropdown className="contextMenuHeader">
                                            <DropdownToggle nav className="notesAdddedBy">
                                                Added by {note.user.userName}
                                                <FaAngleDown />
                                            </DropdownToggle>
                                            {note.isEditable === true &&
                                                <DropdownMenu right>
                                                    <DropdownItem key={note.id} onClick={event => { this.props.EditMxNotes(ref, note) }} >Edit</DropdownItem>
                                                    <DropdownItem onClick={event => { this.props.ShowDeleteMxNoteConfirmation(note) }}>Delete</DropdownItem>
                                                </DropdownMenu>
                                            }
                                            {note.isEditable !== true &&
                                                <DropdownMenu right>
                                                    <DropdownItem key={note.id} >No Applicable Action</DropdownItem>
                                                </DropdownMenu>
                                            }
                                        </UncontrolledDropdown>


                                    </div>
                                    <div className="notesText">
                                        {note.note}
                                    </div>
                                    <div>&nbsp;</div>
                                </div>
                            )}
                        </div>
                    }

                </>
            );
        }
        else {
            return "";
        }
    }

    private renderMddrTable() {
        if (!(this.props.selectedTail != null && this.props.selectedTail.defects.length > 0) && !this.props.isLoading) {
            return (<div className="mddrBox lighterText">

                <b>Status:</b> {this.props.selectedTail.tail.airCrafts.tail} has no MDDR'S
            </div>);
        }

        return (
            <>
                {this.props.selectedTail.defects.map((mddr: any) =>
                    <div key={mddr.id} className="mddrBox">
                        <section className="col-12">
                            <div className="row textHeaderInstance">
                                <div className="col">
                                    <span className="title">MDDR: </span>
                                    <span className="subTitle">{mddr.mddr}</span>
                                </div>
                                <div className="col">
                                    <span className="title">DEFER: </span>
                                    <span className="subTitle">{mddr.defer} </span>
                                </div>
                                <div className="col">
                                    <span className="title">MEL: </span>
                                    <span className="subTitle">{mddr.melNumber} </span>
                                </div>
                                <div className="col">
                                    <span className="title">DAYS: </span>
                                    <span className="subTitle">{mddr.remainDays} </span>
                                </div>
                            </div>
                            <div className="row textHeaderInstance">
                                <div className="col colspan-4">&nbsp;</div>
                            </div>
                            <div className="row textHeaderInstance">
                                <div className="col colspan-4">
                                    <span className="subTitle-black">Description:</span></div>
                            </div>
                            <div className="row textHeaderInstance">
                                <div className="col colspan-4 descriptionText">{mddr.defectDescription}</div>
                            </div>
                            <div className="row textHeaderInstance mddrStatusBox">
                                {this.props.ptwStatus &&
                                    <div className="col-8">
                                        <Select
                                            options={this.props.ptwStatus}
                                            styles={customStyles}
                                            formatGroupLabel={formatGroupLabel}
                                            onChange={event => { this.SelectStatusOnPage(event, mddr.id) }}
                                            value={this.props.ptwStatus.filter(option => option.label === mddr.plannedStatusSubCategory.category.categoryName)[0]
                                                .options.filter((subopt: any) => subopt.label === mddr.plannedStatusSubCategory.subCategoryName)
                                            }
                                        />
                                    </div>}
                                <div className="col-4 statusTag flex flex-end">
                                    <span className={"statusPad" + mddr.plannedStatusSubCategory.category.id}>
                                        {mddr.plannedStatusSubCategory.category.categoryName === "Not Planned to work - reason" ? "NOT PLANNED TO WORK" :
                                            mddr.plannedStatusSubCategory.category.categoryName === "Plan to work" ? "PLANNED TO WORK" : "NOT SCHEDULED"}
                                    </span>
                                </div>
                            </div>
                            <div className="buttonOuterDiv flex flex-end">
                                {
                                    (
                                        (mddr.mddrNotes && mddr.mddrNotes.length < 4)
                                        ||
                                        (!mddr.mddrNotes)
                                    )
                                    &&
                                    <div className="round-empty-button" onClick={() => { this.props.ShowAddNotes(mddr.id); }}>
                                        ADD NOTES
                                    </div>
                                }

                                {mddr.plannedStatusSubCategory.category.id === 1
                                    &&
                                    <div className="round-empty-button margin-left-16" onClick={() => { this.props.ShowAddParts(mddr.id); }}>
                                        ADD PARTS
                                    </div>
                                }
                            </div>
                            <div className="flex flex-space-between">
                                {mddr.plannedStatusSubCategory.category.id === 1 &&
                                    this.renderPlanningParts(mddr.mddrParts)
                                }
                            </div>
                            <div className="flex flex-space-between">

                                {this.renderPlanningNotes(mddr.mddrNotes)
                                }

                            </div>
                        </section>

                    </div>
                )}
            </>
        );
    }

    private renderTailsTable() {

        if (!(this.props.tailRecords != null && this.props.tailRecords.length > 0) && !this.props.isLoading) {
            return (<div className="lighterText">
                <br />
                <br />

                no tails found for this day.
            </div>);
        }

        return (
            <>
                {this.props.tailRecords.map((tailRecord: any) =>
                    <div key={tailRecord.tail.id} className="tailsInstance" onClick={() => { this.props.SelectTail(tailRecord); }}>
                        <div className="checkboxContent">
                            <div className="containerCheckBox">
                                <div className="round">
                                    <input disabled type="checkbox"
                                        checked={tailRecord.defects.some((def: any) => def.plannedStatusSubCategory.category.categoryName === "Not Scheduled") ? false : true}
                                        id={"checkbox" + tailRecord.tail.id} />

                                    <label htmlFor={"checkbox" + tailRecord.tail.id}></label>
                                </div>
                            </div>
                        </div>
                        <div className={this.props.selectedTail && this.props.selectedTail.tail.id === tailRecord.tail.id ? "otherSelected" : "otherContent"}>
                            <div className="flex flex-space-between">
                                <p className="titleTailInstance">{tailRecord.tail.airCrafts.tail}</p>
                                <div className="hideIPFlex">
                                    <UncontrolledDropdown className="contextMenuHeader">
                                        <DropdownToggle nav>
                                            <FaEllipsisH />
                                        </DropdownToggle>
                                        {this.showArchivedFlights ?
                                            <DropdownMenu right>
                                                <DropdownItem className="menuItemMx " onClick={event => { this.props.ShowUnarchiveConfirmation() }} >Unarchive Flight</DropdownItem>
                                            </DropdownMenu>
                                            :
                                            <DropdownMenu right>
                                                <DropdownItem className="menuItemMx border-bottom-line" onClick={event => { this.props.ShowAddMxR() }}>+ Add new MX item</DropdownItem>
                                                <DropdownItem className="menuItemMx border-bottom-line" onClick={() => this.navigateToWorksheet(this.props.selectedTail.tail.id)} >View Worksheet</DropdownItem>
                                                <DropdownItem className="menuItemMx border-bottom-line" onClick={event => { this.handleReplace() }}>Replace Flight</DropdownItem>
                                                <DropdownItem className="menuItemMx " onClick={event => { this.handleArchive() }} >Archive Flight</DropdownItem>
                                            </DropdownMenu>}
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                            <section>
                                <div className="row">
                                    <div className="col tailNumberInstance">{tailRecord.tail.airCrafts.customer.companyName}&nbsp;{tailRecord.tail.airCrafts.acType}</div>
                                    <div className="col middleHeaderInstance">GT: {tailRecord.tail.groundTimeDisplayValue}</div>
                                    <div className="col rightHeaderInstance">Tasks:<b>{
                                        tailRecord.defects.length + tailRecord.tail.mxrequests.filter((mx: any) => mx.mxRequestPlanningStatusId == 1).length
                                    }</b></div>
                                </div>
                                <div className="row">
                                    <div className="col tailNumberInstance">&nbsp;</div>
                                    <div className="col middleHeaderInstance">&nbsp;</div>
                                    <div className="col rightHeaderInstance">&nbsp;</div>
                                </div>
                                <div className="row textHeaderInstance">
                                    <div className="col">{tailRecord.tail.airlineCode}{tailRecord.tail.flightNo} {tailRecord.tail.arrivingStationName}</div>
                                    <div className="col"><b><span>Arr :</span><span className="margin-left-6"> {tailRecord.tail.arrivalDisplayTime}</span></b></div>
                                    <div className="col">{tailRecord.tail.arrivalDisplayDate}</div>
                                </div>
                                <div className="row textHeaderInstance">
                                    <div className="col">{tailRecord.tail.departingAirlineCode}{tailRecord.tail.departingFlightNo} {tailRecord.tail.departingStationName}</div>
                                    <div className="col padding-left-13"><b>Dep : {tailRecord.tail.departureDisplayTime}</b></div>
                                    <div className="col">{tailRecord.tail.departureDisplayDate}</div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}

                <div className="afterspace">
                    &nbsp;
                </div>


            </>
        );

    }

    private renderThumbnails() {
        return (
            <>
                {this.props.mxRequestUploadedFiles.map((fileWrapper) => (
                    <div>

                        <div className="p-2 file-uploaded" key={fileWrapper.id}>
                            <AiOutlineFile className="file-uploaded-icon" />
                            {fileWrapper.uri}
                            {(fileWrapper.uri.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|xls|XLS|xlsx|XLSX)$/i)) && fileWrapper.imageUri &&
                                <a className="viewButtonMx cursor-pointer" onClick={() => { this.openFile(fileWrapper); }}>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z"></path><circle cx="256" cy="256" r="80" fill="none" stroke-miterlimit="10" stroke-width="32"></circle></svg>

                                </a>
                            }
                            {(fileWrapper.uri.match(/.(pdf|PDF)$/i)) && fileWrapper.imageUri &&
                                <a className="viewButtonMx cursor-pointer" href={fileWrapper.imageUri} target="_blank">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z"></path><circle cx="256" cy="256" r="80" fill="none" stroke-miterlimit="10" stroke-width="32"></circle></svg>

                                </a>
                            }
                            {(fileWrapper.uri.match(/.(doc|DOC|docx|DOCX)$/i)) && fileWrapper.imageUri &&
                                <a className="viewButtonMx cursor-pointer" href={"ms-word:ofe|u|" + fileWrapper.imageUri}>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z"></path><circle cx="256" cy="256" r="80" fill="none" stroke-miterlimit="10" stroke-width="32"></circle></svg>
                                </a>
                            }
                            <AiOutlineCloseCircle className="cursor-pointer file-delete-icon" onClick={() => { this.onDelete(fileWrapper.file, fileWrapper.fileName) }} />
                        </div>
                    </div>
                ))}
            </>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.planning,
    PlanningStore.actionCreators
)(Planning as any);

