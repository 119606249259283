import * as React from 'react';
import {
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { withRouter } from 'react-router';
import { FaUser } from 'react-icons/fa';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IoCloseOutline } from 'react-icons/io5'
import { ApplicationState } from '../store';
import * as UserLoginStore from '../store/UserLogin';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

class UserLogin extends React.PureComponent<any> {

    activePathName: string = '';
    unlisten: any = {};

    componentDidMount() {
        this.unlisten = this.props.history.listen((location: any, action: any) => {
            this.activePathName = location.pathname;
        });
        this.activePathName = this.props.history.location.pathname;
        this.forceUpdate();
    }
    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        if (this.props.auth && this.props.auth.isAuthenticated && this.activePathName !== "/") {
            return (
                <>
                    <Modal centered={true} className="softwareVerModal" isOpen={this.props.showAboutModal}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.HideShowAboutModal(); }}><IoCloseOutline /></div>

                                </div>
                            </div>
                            <div className="flex w-100">
                                FEAM Augur Software Version
                            </div>
                        </ModalHeader>
                        <ModalBody className="pt-0">
                            <div className="flex versionLineheight">
                                <span className="softwareVersion">Software Version:</span>
                                &nbsp;
                                <span className="version">{this.props.softwareVersionData.softwareVer}</span>
                            </div>
                            <div className="flex versionLineheight pt-1">
                                <span className="softwareVersion">Release Date:</span>
                                &nbsp;
                                <span className="version">{this.props.softwareVersionData.createdDate}</span>
                            </div>
                            <div className="flex versionLineheight pt-2">
                                <span className="softwareVersion">Release Notes:</span>
                            </div>
                            <div className="flex versionLineheight pt-3">
                                <span className="bugFix">Bug Fixes:</span>
                            </div>
                            {this.props.bugFixes.length > 0 ? (this.props.bugFixes.map((bug: any) =>
                                <div key={bug.id } className="version pt-2"> {bug.releaseNote}
                                </div>))
                                :
                                <div className="version pt-2"> None </div>
                            }
                            <div className="flex versionLineheight pt-3">
                                <span className="bugFix">Added Features:</span>
                            </div>
                            {this.props.addedFeatures.length > 0 ? (this.props.addedFeatures.map((feature: any) =>
                                <div key={feature.id} className="version pt-2"> {feature.releaseNote}
                                </div>))
                                :
                                <div className="version pt-2"> None </div>
                            }
                        </ModalBody>
                    </Modal>
                    <UncontrolledDropdown className={this.activePathName !== "/station" ? 'smdisplaynp dropdownNav' : 'smdisplaynp'}>
                        <DropdownToggle nav caret className={this.activePathName !== "/station" ? ((this.activePathName !== '/FlightBoards') ? "signIn" : "signInDarkMode"): 'signInST'}>

                            {this.activePathName !== "/station" &&
                                < FaUser />
                            }
                        &nbsp;&nbsp;
                        {this.activePathName === "/station"
                                && "Hello, "}



                            {this.props.auth.user.name}
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={()=>this.props.HideShowAboutModal()}>About</DropdownItem>
                            <DropdownItem target="_blank" href='https://www.airtable.com'>Report Issue</DropdownItem>
                            <DropdownItem onClick={this.props.onSignOut}>Sign Out</DropdownItem>

                        </DropdownMenu>
                    </UncontrolledDropdown>

                <UncontrolledDropdown className="smhidden">
                        <DropdownToggle nav caret className={this.activePathName !== "/FlightBoards" ? "signIn" :"signInDarkMode"}>


                            &nbsp;&nbsp;
                        {this.activePathName === "/station"
                                && "Hello, "}



                            {this.props.auth.user.name}
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={()=>this.props.HideShowAboutModal()}>About</DropdownItem>
                            <DropdownItem target="_blank" href='https://www.airtable.com'>Report Issue</DropdownItem>
                            <DropdownItem onClick={this.props.onSignOut}>Sign Out</DropdownItem>

                        </DropdownMenu>
                    </UncontrolledDropdown>
                </>
            );
        }
        else if (this.activePathName !== "/") {
            return (<NavItem>
                <NavLink className="signIn" onClick={this.props.onSignIn} href='#'>Sign in</NavLink>
            </NavItem>);
        }
        else {
            return "";
        }
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state.userLogin,
    UserLoginStore.actionCreators
)(UserLogin as any))